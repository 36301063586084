// -------------------------------------------
//            History Tab Styles
// -------------------------------------------

@import "../../../../styles/helpers";

.history-tab {
  font-size: 13px;

  ul {
    padding: 0 15px;
  }

  .history-item {
    display: block;
    list-style: none;
    padding: 15px 0;
    border-bottom: 1px solid #ddd;
  }

  .time {
    display: block;
    float: right;
    color: #999;
  }

  .user-role {
    $size: 23px;
    background: $color_cyan;
    border-radius: $size;
    height: $size;
    width: $size;
    line-height: $size;
    font-size: $size * .6;
    text-align: center;
    color: #fff;
    display: inline-block;
    margin-right: 5px;

    &.user-super {
      background: #8BC34A;
    }
  }

  .name {
    display: inline-block;
    text-transform: capitalize;
    margin-right: 5px;
  }

  .name,
  .type,
  .value {
    font-size: 15px;
    font-weight: bold;
  }

  .comment-value {
    color: #777;
  }

  .history {
    $size: 25px;
    vertical-align: baseline;
    position: fixed;
    bottom: 30px;
    left: 20px;
    border: none;
    line-height: $size;
    width: $size;
    height: $size;
    font-size: $size;
    padding: 10px;
    box-sizing: content-box;
    border-radius: $size;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
    vertical-align: baseline;
    color: #fff;
    background-color: #449d44;
    border-color: #398439;
    opacity: 0.65 !important;
  }
}
