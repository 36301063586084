aside {
  position: inherit;
  flex: none;
  width: 50%;
  max-height: 86vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.device-table-comp {
  width: inherit;
  max-height: 80vh !important;

  &.details {
    width: 45%;
  }
}

.device-op-tabs {
  min-width: 50%;
  background-color: whitesmoke;
  margin: 0 3px;
  border-radius: 5px;
}

.g5-config-form{
  width: 70%;

  .form-device-config {
    display: flex;
    margin: 10px;

    label {
      width: 280px;
      text-align: end;
      margin: auto 10px auto 0px;
    }
  }

  .btn {
    width: 50%;
    margin: 0 auto;
    display: block;
  }
}

.MuiTableCell-root {
  font-size: 14px !important;
}

.MuiButton-textSizeLarge {
  font-size: 14px !important;
}

.MuiSnackbar-root .MuiAlert-action .MuiButtonBase-root {
  border: 1px solid #333333 !important;
}
