.history-container {
  max-height: 37vh;
  overflow-y: auto;
}

.noise-history-item {
  font-size: 13px;
  color: #3e3e3e;
  padding: 15px 0;
  border-bottom: 1px #ccc solid;

  strong {
    color: #197952;
    padding: 10px;
  }

  .noise-history-time {
    float: right;
    color: #8b8b8b;
    margin-inline: 5px;
  }
}
