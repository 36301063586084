// -------------------------------------------
//            Work Order Modal
// -------------------------------------------
@import "../../../styles/helpers";

.modal-header .close {
  float: right;
}

.workOrderImage {
  width: 96%;
  float: right;
  margin: 5px 1%;
  z-index: 10;
}

.workOrderCanvas {
  width: 500px;
  float: right;
  margin: 5px 1%;
  height: 350px;
  position: absolute;
  z-index: 20;

  div{
    background: #0000 !important
  }

}

.workOrderCanvas canvas {
  background: none !important;
}


.rightSideGuidlinesInWorkOrder {
  padding-right: 30%;
}

.textAreaGuidline {
  width: 160%;
}

.work-order {
  @media (min-width: 768px) {
    .modal-dialog {
      width: 1100px;
    }
  }

  .modal-body {
    @include clear;
  }

  .modal-footer {
    .processing {
      display: inline-block;
      color: $color_cyan;
      width: 120px;
      text-align: left;
    }

    .btn {
      margin: 0 1% 0 0;
    }

    .processing ~ .btn {
      opacity: .4;
      pointer-events: none;
    }
  }

  .panel-map {
    max-height: 610px;
    @include span(46%);
    float: right;
    margin: $space_xs 1%;
    box-shadow: none;
    background: #fff;
  }

  .select-send-service {
    width: 160px;
    text-align: start;
  }
}

.send-area {
  display: flex;
  position: absolute;
  float: left;
  left: 10px;
  bottom: 10px;
  padding: 5px 15px 5px 15px;
  background: lavender;
  border-radius: 30px;
  box-shadow: -4px -1px 5px 1px #050805;

  // span {
  //   min-width: max-content;
  //   margin: auto 0;

  //   input[type=checkbox] {
  //     margin: 0 5px 0 15px;
  //   }
  // }
}

#work-order-body {
  @include clear;
  background: #fff;
  border: none;
}

.helfWidth {
  width: 50%;
  float: left;
}

.form-work-order {
  @include span(98%);
  margin: $space_xs 1%;
  background: #fff;

  .form-group {
    margin-bottom: 12px;
  }

  .control-label {
    padding-right: 0;
    padding-left: 5px;
    line-height: 16px;
    font-size: 12px;
    font-weight: normal;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 0;
  }

  .control-readonly {
    display: inline-block;
    padding-top: 7px;
    line-height: 14px;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

}

.helfWidth {
  .errorContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffebee; // Light red background color
    border: 1px solid #d32f2f; // Dark red border color
    padding: 20px;
    border-radius: 8px;

    .errorIconContainer {
      background-color: #d32f2f; // Dark red background color for the circle
      color: #fff; // White text color
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;

      .errorIcon {
        font-size: 24px;
      }
    }

    .errorTextContainer {
      flex-grow: 1;
    }

    .errorText {
      margin: 0;
      font-weight: bold;
      color: #d32f2f; // Dark red text color
    }
  }


}

