// -------------------------------------------
//            Work Order Modal
// -------------------------------------------
@import "../../../styles/helpers";

.modal-header .close {
  float: right;
}

.pipeAssessmentImage {
  width: 96%;
  float: right;
  margin: 5px 1%;
  z-index: 10;
}

.pipeAssessmentCanvas {
  width: 500px;
  float: right;
  margin: 5px 1%;
  height: 350px;
  position: absolute;
  z-index: 20;
}

.pipeAssessmentCanvas canvas {
  background: none !important;
}


.rightSideGuidlinesInPipeAssessmentReport {
  display: block !important;
}

.textAreaGuidline {
  width: 150%;
}

.pipe-assessment {
  @media (min-width: 768px) {
    .modal-dialog {
      width: 1100px;
    }
  }

  .modal-body {
    @include clear;

    h2 {
      text-align: center;
    }
  }

  .modal-footer {
    .processing {
      display: inline-block;
      color: $color_cyan;
      width: 120px;
      text-align: left;
    }

    .btn {
      margin: 0 1% 0 0;
    }

    .processing ~ .btn {
      opacity: .4;
      pointer-events: none;
    }
  }

  .panel-map {
    max-height: 610px;
    @include span(46%);
    float: right;
    margin: $space_xs 1%;
    box-shadow: none;
    background: #fff;
  }
}

#pipe-assessment-body {
  @include clear;
  background: #fff;
  border: none;
}

.map-container {
  width: 40%;
  float: left;
}

.records-container {
  width: 60%;
  float: left;
}

.form-pipe-assessment {
  @include span(98%);
  margin: $space_xs 1%;
  background: #fff;

  .form-group {
    margin-bottom: 12px;
  }

  .control-label {
    padding-right: 0;
    padding-left: 5px;
    line-height: 16px;
    font-size: 12px;
    font-weight: normal;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 0;
  }

  .control-readonly {
    display: inline-block;
    padding-top: 7px;
    line-height: 14px;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.pipe-assessment-record {
  margin: 5px;
  padding: 0px;
  border: solid 1px black;
  display: block;
  width: max-content;

  p {
    text-align: center;
    font-size: x-large;
    font-weight: bold;
  }

  span {
    text-align: center;
    font-size: large;
    margin: 0 auto;
    display: block;
  }

  th {
    text-align: center;
  }

  td, th {
    padding: 3px 8px;
    font-size: 13px;
  }

  tr:nth-child(even) {
    background-color: rgba(150, 216, 216, 0.5);
  }

  tr:nth-child(odd) {
    background-color: #fff;
  }
}
