// -------------------------------------------
//            Work Order Modal
// -------------------------------------------
@import "../../../../styles/helpers";

.modal-header .close {
  float: right;
}



  .delete-dialog {
    @media (min-width: 768px) {
      .modal-dialog {
        width: 35%;
      }
    }

    .modal-body {
      @include clear;
      display: flex;
    }

    .modal-footer {
      .processing {
        display: inline-block;
        color: $color_cyan;
        width: 120px;
        text-align: left;
      }

      .btn {
        margin: 0 1% 0 0;
      }

      .processing ~ .btn {
        opacity: .4;
        pointer-events: none;
      }
    }

    .panel-map {
      max-height: 610px;
      @include span(46%);
      float: right;
      margin: $space_xs 1%;
      box-shadow: none;
      background: #fff;
    }
  }

  #delete-dialog-body {
    @include clear;
    background: #fff;
    border: none;
  }

  .form-delete-dialog {
    @include span(46%);
    margin: $space_xs 1%;
    background: #fff;

    .form-group {
      margin-bottom: 12px;
    }

    .control-label {
      padding-right: 0;
      padding-left: 5px;
      line-height: 16px;
      font-size: 12px;
      font-weight: normal;
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-bottom: 0;
    }

    .control-readonly {
      display: inline-block;
      padding-top: 7px;
      line-height: 14px;
      max-width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }


.PCA-dialog {
  @media (min-width: 768px) {
    .modal-dialog {
      width: 60%;
    }
  }
}
