// -------------------------------------------
//            Couples Settings Panel
// -------------------------------------------

@import '../../../styles/helpers';

.couples-settings {
  background: #fff;
  border-radius: $radius_md;
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  // visibility: hidden;
  display: none;
  pointer-events: none;
  transform: scale(0.9);
  transition: visibility 0.25s, opacity 0.25s, transform 0.25s;
  &.show {
    transition: visibility 0 0.25s, opacity 0.25s 0, transform 0.25s 0;
    transform: scale(1);
    opacity: 1;
    pointer-events: all;
    display: block;
  }
  .icon-close {
    &:before {
      @include ion-icon('ios-close-empty', 26px, #333);
    }
    &:hover {
      cursor: pointer;
    }
  }
  form {
    padding: 15px 15px 5px;
    @include panel-content-overflow();
  }
  fieldset {
    padding-bottom: 20px;
    legend {
      font-size: $font-size--md + 3;
      // font-weight: bold;
      margin-bottom: 15px;
      color: $color_cyan;
    }
    label {
      font-weight: 500;
    }
  }

  .control-date-form {
    width: 29%;
    padding-right: 0;
  }
  .control-date-to {
    width: 29%;
    padding-left: 0;
  }

  .control-numeric-opr {
    padding-right: 0;
  }

  .form-control {
    box-shadow: none;
  }

  @include input-heights(34px);
}
