// -------------------------------------------
//            Starter Styles
// -------------------------------------------

@import '../../../styles/helpers';

.sample-info {
  position: absolute;
  max-height: 97%;
  bottom: 20px;
  left: 0;
  right: 0;
  padding: 15px 0 10px 0;
  background: #fff;
  box-shadow: 0 0 20px rgba(#000, 0.1);
  margin-bottom: 5px;
  z-index: 999;

  .icon-close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 8px;
    z-index: 1;

    &:before {
      @include ion-icon('ios-close-empty', 36px, #333);
      line-height: 14px;
    }

    &:hover {
      cursor: pointer;
      background: rgba(#fff, 0.5);
    }
  }

  .icon-show-more-info {
    position: absolute;
    right: 50%;
    top: -25px;
    padding: 8px;
    z-index: 1;
    background-color: #ffffffcc;
    border-radius: 5px;

    &:before {
      @include ion-icon('chevron-up', 25px, #333);
      line-height: 14px;
      transform: rotate(0deg);

      transition: transform 0.75s;
    }

    &:hover {
      cursor: pointer;
      background: rgba(#fff, 0.5);
    }

    &.open {
      &:before {
        transform: rotate(180deg);
      }
    }
  }

  .icon-hide-more-info {
    position: absolute;
    right: 50%;
    top: -25px;
    padding: 8px;
    z-index: 1;
    background-color: #ffffffcc;
    border-radius: 5px;

    &:before {
      @include ion-icon('chevron-down', 25px, #333);
      line-height: 14px;
    }

    &:hover {
      cursor: pointer;
      background: rgba(#fff, 0.5);
    }
  }

  .debug {
    display: inline-flex;
    margin: 5px;

    table {
      background-color: #d7f2dd;
      margin: 3px 5px;
      th,
      td {
        border: 1px solid #555;
        padding: 3px;
      }
      hr {
        margin: 0;
      }
    }

    audio {
      width: 200px;
      height: 20px;
      float: left;
    }
  }

  .stripe {
    display: inline-flex;
    margin: 5px;

    table {
      // background-color: #dee3f699;
      background-color: white;
      margin: 3px 5px;
      th,
      td {
        border: 1px solid #555;
        padding: 4px;
        text-align: center;
        font-size: small;
      }
    }

    audio {
      width: 200px;
      height: 40px;
      float: left;
    }
  }

  .advanced-graphs {
    height: 0px;
    opacity: 0;
    visibility: hidden;
    text-align: center;

    transition: height 0.5s, opacity 0.5s;

    &.show {
      height: 480px;
      opacity: 1;
      visibility: visible;
    }

    .history-graphs {
      max-height: 425px;
      overflow: auto;
      background-color: #efefef;
      // background-color: beige;
      margin: 5px;
      padding: 5px;
      border-radius: 10px;
      box-shadow: 1px 2px 3px 1px #35595f;
    }
  }

  .advanced-button {
    position: absolute;
    left: 50%;
    background: rgba(255, 255, 255, 0.5);
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 2px;
    cursor: pointer;

    &.expend {
      bottom: 100%;

      &:before {
        @include ion-icon('ios-arrow-up', 20px, #555);
        line-height: 14px;
      }
    }

    &.collaps {
      bottom: 100%;

      &:before {
        @include ion-icon('ios-arrow-down', 20px, #555);
        line-height: 14px;
      }
    }
  }

  .downloads {
    float: left;
    position: relative;
    margin-right: 20px;
  }

  .btn-download {
    color: #333;

    &:before {
      @include ion-icon('volume-medium', 24px, #333);
      line-height: 14px;
      vertical-align: text-bottom;
      margin-right: 7px;
    }
  }

  .sample-graphs {
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 140px;

    p {
      font-weight: bold;
      margin-bottom: 2px;
    }

    .graph-div {
      width: 100%;
      height: 100%;
      flex: 1;
      text-align: center;
      margin: 0px 5px;
    }

    img {
      max-width: 100%;
      border: 1px solid #bbb;
      padding-left: 8px;

      &:before {
        display: block;
        position: absolute;
        margin-top: -57px;
        margin-left: -1px;
        background: url('../../../../assets/images/system/#{$system}GraphNotAvailable.png')
          no-repeat center;
        background-size: cover;
      }
    }

    tr {
      padding: 2px 5px;
      font-size: initial;
      height: 25px;
    }

    th {
      text-align: center;
    }

    td {
      padding: 5px 10px;
    }
  }

  .mask {
    outline: 14px solid #fff;
    outline-offset: -15px;
  }

  &.g5-bit-msg {
    div {
      padding: 2px;
    }
  }

  &.g5-bit-sample-msg {
    div {
      padding: 2px;
    }
  }
}

.js-plotly-plot {
  margin: 2px;
}

.prev-graphs-with-tooltip {
  display: inline-flex;
  width: 265px;
  height: 100%;
}

.btn-audio-stripe {
  position: absolute;
  bottom: 2px;
  left: 2px;
  z-index: 1;
  cursor: pointer;
}

.MuiTooltip-tooltip {
  font-size: 1.2rem !important;
}
