@import "../../../styles/helpers";

.alert-details {
  opacity: 1;
  transition: opacity .2s;

  &.fetching {
    opacity: .5;
    pointer-events: none;
  }
}
