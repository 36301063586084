// -------------------------------------------
//            Device Tab Styles
// -------------------------------------------

@import "../../../../styles/helpers";

.device-tab {
  &.empty-table {
    .fixedDataTableLayout_main {
      display: none;
    }
  }
  .public_fixedDataTable_main {
    border-width: 1px 0 0;
  }
}
