.bit-msg-freq-data {
  border-collapse: collapse;

  th, td {
    border: 1px solid black;
    padding: 2px 6px;
    text-align: center;
  }
}

.audio-tr {
  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;

    a {
      color: gray;
      pointer-events: none;
    }
  }
}

.MuiInputBase-input.Mui-disabled {
  cursor: not-allowed;
}
