// -------------------------------------------
//           Project picker
// -------------------------------------------

@import "../../../styles/helpers";

.lang-select {
  select {
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: 1px 15px;
    text-align: center;
    text-align-last: center;
    box-sizing: border-box;
    display: block;
    appearance: none;
    background: url('../../../images/arw-select.png') no-repeat #fff;
    background-size: 10px;
    background-position: right 2px bottom 2px;
  }
}
