// -------------------------------------------
//            Details Tab Styles
// -------------------------------------------

@import '../../../../../styles/helpers';

.alert-details {
  .form-horizontal {
    @include clear;
    padding: 15px 0 60px 0;

    .form-group {
      padding: 0;
      margin-left: 0;
      margin-right: 0;
    }

    .control-label {
      padding-right: 0;
      padding-left: 5px;
      line-height: 16px;
      font-size: 12px;
      font-weight: normal;
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-bottom: 0;
    }

    input.form-control,
    select.form-control {
      height: 30px;
      padding: 4px 12px;
    }

    @include input-heights(30px);

    .control-readonly {
      display: inline-block;
      padding-top: 7px;
      line-height: 14px;
      max-width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .form-group,
  .inline-group {
    margin-bottom: 12px;
  }

  .inline-group {
    width: 50%;
    float: left;

    &:nth-child(odd) {
      .control-input {
        // padding-right: 0;
      }
    }

    &:nth-child(even) {
      .control-label {
        padding-left: 0;
      }
    }
  }

  .couple-details-form {
    .couple-details-checkbox {
      display: flex;
      align-items: center;
      margin: 1em;
    }

    .control-label {
      margin-bottom: 0;
    }
  }

  .icon-gallery {
    &:before {
      @include ion-icon('images', 22px, #ffffff);
    }

    // &:hover {
    //   cursor: pointer;

    //   &:disabled {
    //     cursor: not-allowed;
    //   }
    // }

    // &:disabled {
    //   cursor: not-allowed;
    // }
  }
}

.checkbox-control {
  height: 20px;
  width: 20px;

  &.pca {
    margin: 0 10px;
  }
}

.editor-toolbar {
  display: none;
}

.noShow {
  display: none;
}

.show {
  display: block !important;
}

.tel-input {
  width: inherit !important;
  padding-left: 45px !important;
}
