@import "../../../styles/helpers";
.couple-view {
  display: flex;
  background-color: beige;
  border-radius: 15px;
  padding: 5px;
  margin: 5px 10px;
  height: 40%;
}

.sensor-details-container {
  width: 100px;
  height: 100px;
}

.sensor-details {
  display: grid;
  vertical-align: middle;

  h3 {
    margin: 0;
    text-align: center;
  }

  input {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0;
    width: 75px !important;
    border: none;
    border-color: transparent;
    background: transparent;
    // bottom: 71%;
    max-width: 100px;

    $space: 3%;

    &.first {
      // float: left;
      text-align: start;
      left: $space;
    }

    &.second {
      // float: right;
      text-align: end;
      right: $space;
    }
  }
}

.path-details {
  width: 100%;
  position: relative;
}

.btn-setup-couple {
  width: 40%;
  height: 100%;
  margin: 0 auto;
  display: block;

  span {
    text-align: center;
    right: 150%;
    width: 100%;
    position: relative;
  }
}

.couple-generation-controll {
  float: left;
  // margin: 5px;
  // padding: 10px;
  width: 90%;
  margin-left: 15px;
}

.couple-path-table {
  float: left;
  padding: 3px;
  margin-left: 12%;

  .title {
    margin-left: 10px;
  }

  .path-points-table {
     border: solid 1px #aaa;
     border-radius: 6px;
  }
}

.center {
  display: block;
  margin: 0 auto;
}

.edit {
  cursor: pointer;

  &.editting {
    &:before {
      @include ion-icon("edit", 22px, black);
      padding: 5px 8px 5px 9px;
    }
  }

  &.save {
    &:before {
      @include ion-icon("ios-checkmark", 22px, green);
      padding: 5px 8px 5px 9px;
    }
  }

  &:hover {
    text-shadow: 0px 0px 20px orange;
  }
}


.clear {
  cursor: pointer;

  &:before {
    @include ion-icon("trash-b", 22px, #333);
    padding: 5px 8px 5px 9px;
  }

  &:hover {
    text-shadow: 0px 0px 20px orange;
  }
}

.is-disabled {
  background-color: #eee;
}

.react-draggable {
  width: 50%;
  position: absolute;

  .handle {
    cursor: move;
  }

  &.actual-leak-position-form {
    background-color: aliceblue;
    border: #aaa solid 1px;
    border-radius: 6px;
    margin: 10px;
    box-shadow: cadetblue 3px 3px 10px;
    opacity: 0.85;

    .draggable-header{
      display: flex;
      background-color: lightblue;

      p {
        padding: 4px;
        margin: 5px 0;
        width: 100%;
        font-size: large;
        font-weight: bold;
        text-align: center;
      }

      .close {
        padding: 5px 8px 5px 6px;
        display: block;
        float: right;

        &:before {
          @include ion-icon("android-close", 22px, #333);
        }

        &:hover {
          cursor: pointer;
        }
      }

      .handle {
        padding: 5px 8px 5px 6px;
        display: block;
        float: left;

        &:before {
          @include ion-icon("arrow-move", 22px, #333);
        }

        &:hover {
          cursor: move;
        }
      }
    }

    form {
      padding: 7px;
    }

    .tooltip {
      position: absolute;
      top: 80%;
      left: 10%;
      background: #fff;
      border: 1px solid #CB9471;
      display: block;
      z-index: 9;
      opacity: 1;
      /* padding: 5px 10px; */
      margin-top: 9px;
      color: #bd784c;
      font-size: 0.9em;
      max-width: 100%;
      bottom: auto;
      @include triangle(top, 15px, #fff, $color_brown);
    }
  }
}


.icon-back {
  &::before {
    @include ion-icon("android-arrow-dropleft", 26px, #333);
  }

  &.disabled {
    &::before {
      @include ion-icon("android-arrow-dropleft", 26px, cadetblue);
    }
  }
}

.icon-next {
  &::after {
    @include ion-icon("android-arrow-dropright", 26px, #333);
  }

  &.disabled {
    &::after {
      @include ion-icon("android-arrow-dropright", 26px, cadetblue);
    }
  }
}

.pages-browser-element {
  display: table-cell;
  height: 100%;
}

.pages-browser {
  float: left;

  &.content {
    width: 100%;
  }
  &.navigate {
    position: absolute;
    top: 40%;
    z-index: 100;
    margin: 0 3px;
    padding: 30px 5px;
    background-color: deepskyblue;
    border: solid grey 1px;
    border-radius: 2px;

    &.prev{
      left: 0;
    }
    &.next {
      right: 0;
    }

    &.disabled {
      background-color: lightblue;
      &:hover {
        cursor: not-allowed;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.pressure-display-mode {
  width: 150px;
  z-index: 10;
  position: absolute;
  top: 0px;
}

.timezone-picker {
  display: inline-block;
  font: 13px sans-serif;
  position: relative
}

.timezone-picker-textfield input {
  width: 100%;
  padding: 9px 12px;
  font: inherit;
  box-sizing: border-box;
  outline: 0;
  background: #fff;
  border: 1px solid #e6ebec;
  border-radius: 2px
}

.timezone-picker-list {
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  border: 1px solid #e6ebec;
  margin-top: -1px;
  border-radius: 0 0 3px 3px;
  display: none
}

.timezone-picker-list-rel {
  position: relative
}

.timezone-picker-list-abs {
  position: absolute;
  background-color: #fff;
  z-index: 2000;
  opacity: .99
}

.timezone-picker-list-item {
  color: #444;
  padding: 5px 12px
}

.timezone-picker-list-item-active {
  background: #ececec;
  cursor: pointer
}

.timezone-picker-open .timezone-picker-list {
  display: block
}

.timezone-picker-selected .timezone-picker-textfield input {
  color: #474747
}

.couple-fields {
  margin-top: 30px;
}

