// -------------------------------------------
//            History Tab Styles
// -------------------------------------------

@import '../../../../styles/helpers';

.couples-table {
  .public_fixedDataTable_header {
    text-align: left;
  }
  .public_fixedDataTable_bodyRow {
    @extend %data-table-text-overflow;
  }

  // width: -webkit-fill-available;
  // display: inline-block;
  // overflow-y: scroll;
  height: 285px;
  float: left;

  thead th {
    position: sticky;
    top: 0;
    background-color: white;
  }

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  th {
    text-align: center;
  }

  tbody {
    // display:block;
    // overflow:overlay;
    // height:200px;
    // width:100%;

    tr:hover {
      background-color: #ecf3ff;
    }

    .selected {
      background-color: #ffefc5;
    }

    // tr:active {
    //   background-color: #FFEFC5;
    // }

    // td {
    //   width: calc( 18vw - 1em );
    // }
    // td:first-child {
    //   width: 3vw;
    // }
  }
  // thead {
  //   tr {
  //     display:block;
  //   }
  //   // th {
  //   //   width: calc( 18vw - 1em );
  //   // }
  //   // th:first-child {
  //   //   width: 3vw;
  //   // }
  // }

  // width: 716px; /* 140px * 5 column + 16px scrollbar width */
  // border-spacing: 0;

  // tbody, thead tr { display: block; }

  // tbody {
  //     height: auto;
  //     overflow-y: auto;
  //     overflow-x: hidden;
  // }

  // tbody td, thead th {
  //     width: 140px;
  // }

  // thead th:last-child {
  //     width: 156px; /* 140px + 16px scrollbar width */
  // }
}

.center {
  text-align: center;
}
// fixedDataTableRowLayout_main public_fixedDataTableRow_main fixedDataTableLayout_header public_fixedDataTable_header

// fixedDataTableRowLayout_main public_fixedDataTableRow_main public_fixedDataTableRow_even public_fixedDataTable_bodyRow

.algo {
  border-radius: 15px;
  height: 15px !important;
  width: 15px !important;
  line-height: 15px;
  padding: 0;
  text-align: center;
  margin: auto;

  &.red {
    background-color: red;
  }

  &.green {
    background-color: green;
  }

  &.yellow {
    background-color: yellow;
  }

  &.grey {
    background-color: grey;
  }

  &.orange {
    background-color: orange;
  }

  &.blue {
    background-color: blue;
  }
}

.MuiIconButton-colorSecondary {
  // color: #f50057;
  background-color: mistyrose !important;
}

.MuiTableCell-paddingNone {
  padding: 3px !important;
}

.MuiFlatPagination-root {
  text-align: center;
}

.MuiButton-textPrimary {
  background: #afa3ef6b !important;
  // -moz-border-radius: 70px;
  // -webkit-border-radius: 70px;
  border-radius: 5px !important;
}
