// -------------------------------------------
//            Legend Panel
// -------------------------------------------

@import "../../../../styles/helpers";

.scale {
  position: absolute;
  left: 0;
  top: 65px;
  z-index: 3;
  background: #fff;
  width: 260px;
  max-width: 70%;
  box-shadow: rgba(#000, 0.4) 0px 1px 4px -1px;
  padding: 0 25px;
  transform: translateX(-100%);
  transition: transform .25s ease-in-out;

  &.show {
    transform: translateX(0);
  }

  .actions {
    background: #fff;
    position: absolute;
    top: 0;
    left: 100%;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 4px -1px;
    min-height: 32px;
    min-width: 32px;

    &:after {
      content: '';
      position: absolute;
      background: #fff;
      top: 0;
      bottom: 0;
      right: 100%;
      width: 10px;
    }
  }

  .icon-toggle {
    padding: 5px 8px 5px 6px;
    display: block;

    &:before {
      @include ion-icon("map", 22px, #333);
    }

    &:hover {
      cursor: pointer;
    }
  }

  &.show .icon-toggle {
    padding: 5px 6px 5px 8px;

    &:before {
      content: "\F404";
    }
  }

  .distance-data {
    padding: 10px 0px;
    position: relative;
    height: 60px;

    h1 {
      float: left;
      margin: auto;
    }

    .units {
      position: absolute;
      bottom: 25px;
    }

    .clear {
      float: right;
      border: #aaa solid 1px;
      border-radius: 3px;
      padding: 3px;

      &:before {
        @include ion-icon("trash-a", 22px, #333);
      }

      &:hover {
        background: #c3e6e3;
      }

      &:active {
        background: #f5ea95;
      }
    }
  }
}
.crop-select {
  position: absolute;
  left: 0;
  top: 100px;
  z-index: 3;
  background: #fff;
  width: 295px;
  max-width: 70%;
  min-height: 100px;
  box-shadow: rgba(#000, 0.4) 0px 1px 4px -1px;
  padding: 10px 15px;
  transform: translateX(-100%);
  transition: transform .25s ease-in-out;

  &.show {
    transform: translateX(0);
  }

  h1 {
    font-size: 15px;
    font-weight: bold;
  }

  .actions {
    background: #fff;
    position: absolute;
    top: 0;
    left: 100%;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 4px -1px;
    min-height: 32px;
    min-width: 32px;

    &:after {
      content: '';
      position: absolute;
      background: #fff;
      top: 0;
      bottom: 0;
      right: 100%;
      width: 10px;
    }
  }

  .icon-toggle {
    padding: 5px 8px 5px 6px;
    display: block;

    &:before {
      @include ion-icon("crop", 22px, #333);
    }

    &:hover {
      cursor: pointer;
    }
  }

  &.show .icon-toggle {
    padding: 5px 6px 5px 8px;

    &:before {
      content: "\F404";
    }
  }
  .crop-select-buttons {
    display: grid;
    grid-template-columns: auto auto auto;
    align-items: center;
    column-gap: 5px;
  }
}
