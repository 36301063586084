
@import "../../../../styles/helpers";

.control-readonly {
  display: inline-block;
  padding-top: 7px;
  line-height: 14px;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.indicator-icon {
  max-width: none !important;
  border: none !important;
}
