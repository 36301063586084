@import "../../../styles/helpers";

.search-item {
  $height: 27px;

  height: $height;
  display: flex;
  align-items: center;

  .input-field {
    height: $height;
    width: 150px;
    line-height: $height;
    padding: 0 7px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);

    &:before {
      @include ion-icon("search", 24px, #ccc);
    }
    // &:after {
    //   @include ion-icon("search", 24px, #ccc);
    // }
  }

  .search-icon {
    &:after {
      @include ion-icon("search", 24px, #ccc);
      position: relative;
      right: 25px;
    }
  }


  button {
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
  }

  select {
    height: $height;
  }

  .search {
    width: $height;
    height: $height;
    float: left;
    background-image: url('../../../images/ic-search.png');
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;

    &[disabled] {
      background-image: url('../../../images/ic-search-disable.png');
      cursor: default;
    }
  }

  .type {
    width: 100px;
    margin-right: 2px;

    .Select {
      &-value {
        line-height: $height;
      }

      &-control {
        height: $height;
      }

      &-input {
        height: 25px;
      }
    }
  }
}
