
// ----------------------------------------------
//               Sass shared classes
// ----------------------------------------------

%data-table-text-overflow {
  .public_fixedDataTableCell_cellContent {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .fixedDataTableCellLayout_wrap1 {
    width: 100%;
    table-layout: fixed;
  }
}
