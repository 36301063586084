
// ----------------------------------------------
//               Sass Mixins
// ----------------------------------------------

// Image Replacement
@mixin ir($img, $width, $height, $x: 50%, $y: 50%) {
  background: {
    image: url($img);
    repeat: no-repeat;
    position: $x $y;
  }
  width: $width;
  height: $height;
  text-indent: $width;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

@mixin sprite($x, $y, $hoverx: false, $hovery: false, $child: '') {
  & #{$child} {
    background: {
      image: url('../../images/sprites.png');
      repeat: no-repeat;
      position: $x $y;
    }
  }
  @if ($hoverx 'and' $hovery) {
    &:hover #{$child} {
      background-position: $hoverx $hovery;
    }
  }
}

@mixin panel($width: 50%) {
  @include span($width - .5);
  margin: $space_xs .25%;
  background: #fff;
  border-radius: $radius_md;
  box-shadow: 0 0 10px rgba(#000, .3);
}
@mixin panel-border-fix() {
  // This fixes the border radius in WebKit
  border-radius: $radius_md - 2;
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
}

@mixin panel-content-overflow($top: 36px) {
  position: absolute;
  top: $top;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  border-radius: 0 0 $radius_md 0;
  @include panel-border-fix;
}

@mixin input-heights($height: 34px) {
  .Select-control {
    height: $height;
  }
  .Select-placeholder,
  .Select--single .Select-value {
    line-height: $height - 2px;
  }
  .Select-input {
    height: $height - 2px;
  }
}

@mixin triangle(
  $dir: top,
  $pos: 50%,
  $color1: #fff,
  $color2: darken($color1, 30%),
  $size1: 6px,
  $size2: $size1
) {
  $opos: map-get(( top: bottom, right: left, bottom: top, left: right ), $dir);
  $transparent: if($dir == left or $dir == right, (top, bottom), (left, right));
  &:before,
  &:after {
    #{nth($transparent, 1)}: $pos;

    height: 0;
    width: 0;
    display: block;
    content: " ";
    position: absolute;
  }
  &:before {
    border-#{nth($transparent, 1)}: #{$size2 + 1} solid transparent;
    border-#{nth($transparent, 2)}: #{$size2 + 1} solid transparent;
    border-#{$opos}: #{$size1 + 1} solid $color2;
    margin-#{nth($transparent, 1)}: -1 - $size2;
    #{$dir}: -1 - $size1;
  }
  &:after {
    border-#{nth($transparent, 1)}: $size2 solid transparent;
    border-#{nth($transparent, 2)}: $size2 solid transparent;
    border-#{$opos}: $size1 solid $color1;
    margin-#{nth($transparent, 1)}: - $size2;
    #{$dir}: - $size1;
  }
}
