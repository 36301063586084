// -------------------------------------------
//            History Tab Styles
// -------------------------------------------

@import "../../../../styles/helpers";

.samples-table {
  .public_fixedDataTable_header {
    text-align: left;
  }
  .public_fixedDataTable_bodyRow {
    @extend %data-table-text-overflow;
  }
}

.center {
  text-align: center;
}

// fixedDataTableRowLayout_main public_fixedDataTableRow_main fixedDataTableLayout_header public_fixedDataTable_header

// fixedDataTableRowLayout_main public_fixedDataTableRow_main public_fixedDataTableRow_even public_fixedDataTable_bodyRow
