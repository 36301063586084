// -------------------------------------------
//            Reports Table
// -------------------------------------------

@import "../../../styles/helpers";

.reports-wrp {
  @include panel(100%);
  position: relative;
  overflow: hidden;
  h2 {
    font-weight: 100;
    margin: 0;
    padding: 7px 15px;
  }
  .actions {
    float: right;
    padding: 5px 12px;
  }
  .icon-settings {
    &:before {
      @include ion-icon("funnel", 26px, #333);
    }
    &:hover {
      cursor: pointer;
    }
  }
}

.reports-header {
  padding-bottom: 6px;
  h2 {
    display: inline-block;
    line-height: 28px;
  }
  .form-horizontal {
    display: inline-block;
    vertical-align: top;
    padding: 7px 15px;
    @include input-heights(28px);
  }
  .tooltip-cont {
    display: inline-block;
  }
  .Select {
    min-width: 200px;
    margin-right: 15px;
  }
  .icon-settings:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    background: rgba(#000, 0.2);
    z-index: 1;
    opacity: 0;
    transition: opacity .25s;
  }
  &.settings-show .icon-settings:after {
    width: 100%;
    height: 100%;
    opacity: 1;
  }

}

.reports-settings {
  max-width: 600px;
  left: auto;
  right: 0;
  box-shadow: rgba(#000, 0.6) 0px 1px 4px -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  transform: scale(1) translateX(100%);
  &.show {
    transform: translateX(0);
  }
}

.reports-table {
  @extend %data-table-text-overflow;

  .fixedDataTableLayout_main {
    background: #fff;
  }
}
