// -------------------------------------------
//            Alerts Settings Panel
// -------------------------------------------

@import '../../../styles/helpers';

.alerts-settings {
  background: #fff;
  border-radius: $radius_md;
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  display: none;
  transform: scale(0.9);
  transition: opacity 0.25s, transform 0.25s;
  &.show {
    transition: opacity 0.25s 0, transform 0.25s 0;
    transform: scale(1);
    opacity: 1;
    display: block;
  }
  .icon-close {
    &:before {
      @include ion-icon('ios-close-empty', 26px, #333);
    }
    &:hover {
      cursor: pointer;
    }
  }
  form {
    padding: 15px 15px 5px;
    @include panel-content-overflow();
  }
  fieldset {
    padding-bottom: 20px;
    legend {
      font-size: $font-size--md + 3;
      margin-bottom: 15px;
      color: $color_cyan;
    }
    label {
      font-weight: 500;
    }
  }

  .control-date-form {
    width: 29%;
    padding-right: 0;
  }
  .control-date-to {
    width: 29%;
    padding-left: 0;
  }

  .control-numeric-opr {
    padding-right: 0;
  }

  .form-control {
    box-shadow: none;
  }

  @include input-heights(34px);
}

.add-alert-form {
  background: #fff;
  border-radius: $radius_md;
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 818px;
  opacity: 0;
  visibility: visible;
  transform: scale(0.9);
  transition: visibility 0.25s, opacity 0.25s, transform 0.25s;
  display: none;

  h2 {
    font-weight: bold;
  }

  &.show {
    transition: visibility 0 0.25s, opacity 0.25s 0, transform 0.25s 0;
    transform: scale(1);
    opacity: 1;
    display: block;
  }

  .icon-close {
    &:before {
      @include ion-icon('ios-close-empty', 26px, #333);
    }
    &:hover {
      cursor: pointer;
    }
  }

  .new-alert-header {
    text-align: center;
    padding: 10px 0px;
  }

  fieldset {
    padding: 0 20px;
  }

  .tooltip-cont {
    position: relative;
  }

  .tooltip {
    position: absolute;
    top: 80%;
    left: 0;
    background: #fff;
    border: 1px solid $color_brown;
    display: block;
    z-index: 9;
    opacity: 1;
    padding: 5px 10px;
    margin-top: 9px;
    color: darken($color_brown, 10%);
    font-size: 0.9em;
    max-width: 100%;
    @include triangle(top, 15px, #fff, $color_brown);
  }

  .auto-center {
    margin: 0 auto 15px;
  }

  .form-actions {
    padding: 10px 15px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    // box-shadow:  0 0 5px rgba(#000, .2);
    border-top: 1px solid #ddd;
    background: rgba(#eee, 0.8);

    .btn {
      margin: 0 5px;
    }
  }
}
