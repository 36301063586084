// -------------------------------------------
//            Work Order Modal
// -------------------------------------------
@import "../../../styles/helpers";

.modal-header .close {
  float: right;
}

.coupleAssessmentReportImage {
  width: 80%;
  float: left;
  margin: 5px;
  z-index: 10;
}

.coupleAssessmentReportCanvas {
  width: 500px;
  float: right;
  margin: 5px 1%;
  height: 350px;
  position: absolute;
  z-index: 20;
}

.coupleAssessmentReportCanvas canvas {
  background: none !important;
}


.rightSideGuidlinesInCoupleAssessmentReport {
  /*background: cadetblue;*/
  display: inline-block;
  float: right;
  width: 50%;

  th {
    text-align: center;
  }

  td, th {
    padding: 5px 10px;
    text-align: center;
  }

  tr:nth-child(even) {
    background-color: rgba(150, 216, 216, 0.5);
  }

  tr:nth-child(odd) {
    background-color: #fff;
  }
}


.couple-assessment-report {
  @media (min-width: 768px) {
    .modal-dialog {
      width: 1100px;
    }
  }

  .modal-body {
    @include clear;
  }

  .modal-footer {
    .processing {
      display: inline-block;
      color: $color_cyan;
      width: 120px;
      text-align: left;
    }

    .btn {
      margin: 0 1% 0 0;
    }

    .processing ~ .btn {
      opacity: .4;
      pointer-events: none;
    }
  }

  .panel-map {
    max-height: 610px;
    @include span(46%);
    float: right;
    margin: $space_xs 1%;
    box-shadow: none;
    background: #fff;
  }
}

#couple-assessment-report-body {
  @include clear;
  background: #fff;
  border: none;

  h2 {
    text-align: center;
  }
}

.helfWidth {
  width: 50%;
  float: left;
}

.form-couple-assessment-report {
  @include span(98%);
  margin: $space_xs 1%;
  background: #fff;

  .form-group {
    margin-bottom: 12px;
  }

  .control-label {
    padding-right: 0;
    padding-left: 5px;
    line-height: 16px;
    font-size: 12px;
    font-weight: normal;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 0;
  }

  .control-readonly {
    display: inline-block;
    padding-top: 7px;
    line-height: 14px;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
