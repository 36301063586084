// -------------------------------------------
//           Project picker
// -------------------------------------------

@import '../../../styles/helpers';

.project-picker {
  position: absolute;
  display: flex;
  z-index: 1;
  // @media #{$desktop-only} {
  top: -36px;
  right: 140px;
  // left: auto;
  // }
  select {
    color: #222;
    font-family: Tahoma, Helvetica, sans-serif;
    font-weight: 100;
    border: 1px solid #ccc;
    border-radius: 2px;
    font-size: 18px;
    padding: 3px 15px;
    min-width: 100px;
    // text-align: center;
    text-align-last: center;
    box-sizing: border-box;
    display: block;
    appearance: none;
    background: url('../../../images/arw-select.png') no-repeat #fff;
    background-size: 12px;
    background-position: right 2px bottom 2px;
  }
}

.projectPickerBtn {
  padding-top: 4px !important;
  cursor: pointer;
  // margin-left: -40px;
  margin-left: -2em;
  position: absolute;

  &:after {
    @include ion-icon('earth', 20px, #000);
  }
}

.reportsBtn {
  padding-top: 4px !important;
  cursor: pointer;
  position: absolute;
  margin-right: 7em;

  &:after {
    @include ion-icon('ios-list-outline', 20px, #000);
  }
}

.projectsFilterInput {
  width: 40%;
  min-height: 40px;
  float: left;
}

.admin-report-con {
  .admin-report-content-box {
    height: '80%';
    background-color: '#ffe3f2';
  }

  .css-37jdci-MuiTypography-root,
  p {
    margin-bottom: 1em !important;
    font-size: 1.2em !important;
    color: aqua !important;
    background-color: antiquewhite;
  }
}

.admin-report-modal {
  .makeStyles-unsetOverflowY-1,
  .makeStyles-container-3,
  .makeStyles-unsetOverflowY-20 {
    overflow: hidden !important;
  }
}
