.dashboard-page {
  height: 88vh;

  header {
    display: flex;
    justify-content: space-between;
    background-color: lightblue;
    padding: 10px;
  }

  main {
    margin: 10px;
    padding: 15px;
    background-color: white;
    border-radius: 5px;
    height: 100%;
  }

  .dashboard-container {
    display: flex;
    align-items: center;

    aside {
      width: unset;
    }
  }

  .dashboard-filters {
    display: flex;
    column-gap: 10px;
    align-items: flex-start;

    .from-to-date {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: end;
    }

    input {
      margin-left: 10px;
    }
  }
}
