@import '../../../styles/helpers';

.modal-header .close {
  float: right;
}

.checkbox-iq100b-mobile {
  transition: all 1s ease;
  margin: 2px 0px 2px 0px;
  display: flex;
  align-items: center;
  input,
  label {
    margin: 0px;
    padding: 0px;
    cursor: pointer;
    background: #ececec;
    border: 1px solid #cfcfcf;
    border-radius: 4px;
  }
  input {
    margin-right: 5px;
  }
  label {
    width: 100px;
    text-align: center;
  }
}

.iq100b-export-buttons {
  margin-top: 10px;
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.checkbox-iq100b-mobile-button-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 320px;
  max-width: 320px;
}

.checkbox-iq100b-mobile-type-view {
  transition: all 1s ease;
  margin: 5px 0px 5px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 30px;
  max-height: 30px;
}

.checkbox-iq100b-mobile-selected-row-type {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  input,
  label {
    cursor: pointer;
    padding: 0px;
    margin: 0px;
    font-weight: 400;
  }
}

.checkbox-iq100b-mobile-user-list {
  transition: all 1s ease;
  min-width: 320px;
  max-width: 320px;
  min-height: 40px;
  margin-bottom: 10px;
}

.checkbox-iq100b-mobile-type-view-daily {
  transition: all 1s ease;
  min-width: 320px;
  max-width: 320px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkbox-iq100b-mobile-type-view-monthly {
  transition: all 1s ease;
  min-width: 320px;
  max-width: 320px;
}

.modal-dialog {
  width: fit-content;
  .modal-body {
    .form-group {
      display: flex;
    }

    &.projects-table {
      padding: 0px !important;
      color: black;
    }

    .project-row {
      padding: 3px;

      h3 {
        float: left;
      }

      .project-details {
        float: left;
        margin-left: 15%;
      }
    }

    .project-details-cell {
      /*text-align: center;
      text-anchor: middle;*/
      display: inline-block;
      margin: 14px auto;
    }

    .project-customer {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      font-size: 20px;
      color: #fff;
      line-height: 30px;
      text-align: center;
      padding-top: 2%;
      float: left;
    }
  }
}

.btn-assessments-report {
  $size: 60px;
  position: absolute;
  top: 0;
  left: 40px;
  width: $size;
  height: $size;
  margin-top: 5px;
  border: solid 1px #4cae4c;
  border-radius: 30px;
  opacity: 0.4;
  background-image: url('../../../images/ic-pipe-assessment.png');
  background-repeat: no-repeat;
  background-size: 25px;
  background-position-x: 26px;
  background-position-y: 26px;

  &:after {
    @include ion-icon('ios-list-outline', 20px, #000);
    color: #ffffffaa;
    font-weight: bold;
    font-size: 30px;
    margin-top: -5px;
  }

  &:hover {
    opacity: 1;
  }

  div {
    &:after {
    }
  }
}

.dma-history-modal {
  width: 800px;
}

.project-modal-btn-box {
  display: flex;
  justify-content: space-evenly;
  min-width: 500px;
}
.projects-modal {
  .modal-content {
    background-color: #319aa1;
    color: white;
  }

  .filter-projects-field {
    text-align: start;
    width: 20%;
    float: left;
    margin: 0 10px;
    min-width: 170px !important;
  }

  .h3 {
    font-size: 1em;
  }
}
