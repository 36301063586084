// -------------------------------------------
//            Layer Manager Panel
// -------------------------------------------

@import "../../../styles/helpers";

.layer-manager {
  position: absolute;
  right: 0;
  top: 40px;
  z-index: 2;
  background: #fff;
  width: 260px;
  max-width: 70%;
  box-shadow: rgba(#000, 0.4) 0px 1px 4px -1px;
  padding: 0 25px;
  transform: translateX(100%);
  transition: transform .25s ease-in-out;

  &.show {
    transform: translateX(0);
  }

  .actions {
    background: #fff;
    position: absolute;
    top: 0;
    right: 100%;
    box-shadow: rgba(#000, 0.4) 0px 1px 4px -1px;
    min-height: 32px;
    min-width: 32px;

    &:after {
      content: '';
      position: absolute;
      background: #fff;
      top: 0;
      bottom: 0;
      left: 100%;
      width: 10px;
    }
  }

  .icon-toggle {
    padding: 5px 8px 5px 6px;
    display: block;

    &:before {
      @include ion-icon("ios-browsers-outline", 22px, #333);
    }

    &:hover {
      cursor: pointer;
    }
  }

  &.show .icon-toggle {
    padding: 5px 6px 5px 8px;

    &:before {
      content: "\F404";
    }
  }

  h2 {
    font-weight: 100;
    margin: 0;
    padding: 25px 0 15px;
  }

  form {
    padding: 10px 0;
  }

  label {
    font-weight: normal;
    padding-left: 10px;
  }
}
