// -------------------------------------------
//            Details Tab Styles
// -------------------------------------------

@import "../../../styles/helpers";

.comment-area {
  display: flex;
  margin-bottom: 12px;

  .btn-info {
    margin-left: 8px;

    i:before {
      @include ion-icon("edit", 30px);
      margin: 3px 0px;
    }
  }

  .text-dir-button {
    float: left;
    margin: auto 0px auto 0px;
  }

  .direction-button {
    background: #f7f3f3;
    margin: 1px auto 1px auto;
    border-radius: 4px;
    padding: 2px;
  }

  .direction-button:hover {
    background: #e9e2e2;
  }

  .direction-button:active {
    background-color: #b0b0b0;
  }

  .copy-button {
    background: #f7f3f3;
    margin: 1px auto 1px auto;
    border-radius: 4px;
    padding: 2px;
  }

  .copy-button:hover {
    background: #e9e2e2;
  }

  .copy-button:active {
    background-color: #b0b0b0;
  }

  .blank-button-20px {
    background: #ffffff;
    margin: 1px auto 1px auto;
    border-radius: 4px;
    padding: 2px;
  }
}
