.slider-wrapper {
  height: 65vh !important;
}

.carousel {
  .slide {
    .legend {
      top: 20px;
      bottom: unset;
    }
  }
}
