// -------------------------------------------
//                   Pagination
// -------------------------------------------

@import "../../../styles/helpers";
$flow: right;

.paginate ul {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}

.paginate li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
  &:active {
    font-weight: bold;
    background-color: aquamarine;
  }
}

.paginate .break a {
  cursor: default;
}

.btn-assessment {
  $size: 38px;
  vertical-align: baseline;
  position: absolute;
  bottom: 30px;
  right: 20px;
  border: none;
  line-height: $size;
  width: $size;
  height: $size;
  font-size: $size;
  padding: 10px;
  box-sizing: content-box;
  border-radius: $size;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
  vertical-align: baseline;
  color: #fff;
  background-color: #ff9900;
  border-color: #ff9900;
  opacity: 0.25 !important;


  &.assessments {
    /*@include ion-icon("clipboard", $size);*/
    background-image: url('../../../images/ic-pipe-assessment.png');
    background-size: 35px 35px;
    background-position: center center;
    background-repeat: no-repeat;
    vertical-align: baseline;
    right: -10%;
    top: 110px;

    &.success {
      opacity: 1 !important;
    }
  }

  &.create-item {
    $size: 25px;
    background-color: #5cb85c;
    border-color: #4cae4c;
    border-radius: $size;
    float: right;
    pointer-events: auto;
    opacity: 0.8 !important;
    width: $size;
    height: $size;

    i:before {
      @include ion-icon("plus", $size);
      vertical-align: top;
    }
  }

  &.back {
    opacity: 1 !important;
    right: -10%;
    top: 110px;

    i:before {
      color: #fff;
      @include ion-icon("chevron-right", $size);
      vertical-align: baseline;
    }
  }

  &.btn-no-items {
    background-color: crimson;
  }
}

.assessment-table {

  .gray-panel {
    top: 0px;
    height: 29px;
    background-color: #97989A;

    span {
      position: absolute;
      display: block;
      width: 100%;
      top: 2px;
      text-align: center;
      color: white;
      font-size: 22px;
    }
  }
}
