
// ----------------------------------------------
//                  Variables
// ----------------------------------------------

// Colors
$color_text: #333;
$color_background: #75cae3;
$color_blue: #6090B1;
$color_cyan: #75cbe5 ;
$color_brown: #CB9471;
$color_green: #7B7C5F;
$color_orange: #E5A761;
$color_purple: #7E54A3;
$color_grey: #b3b3b3ff;
$color_red: #ff0000ff;
$color_pink_dark: #ff6b6bff;
$color_pink_light: #ffb0b0ff;

// Spacing
$space_lg: 40px;
$space_md: 20px;
$space_sm: 10px;
$space_xs: 5px;

// Font sizing
$font-size--xs: 10px;
$font-size--sm: 12px;
$font-size--md: 14px;
$font-size--lg: 21px;
$font-size--xl: 26px;

$font-size-h1: $font-size--xl;
$font-size-h2: $font-size--lg;
$font-size-h3: 18px;
$font-size-h4: 16px;
$font-size-h5: $font-size--md;
$font-size-h6: $font-size--sm;

$font-family: Helvetica, sans-serif;

$font: $font-size--md $font-family;

// Border radius
$radius_md: 8px;
$radius_sm: 4px;

//== Media queries breakpoints
$screen-xs:                  480px !default;
$screen-sm:                  768px !default;
$screen-md:                  992px !default;
$screen-lg:                  1200px !default;
$screen-xs-max:              ($screen-sm - 1) !default;
$screen-sm-max:              ($screen-md - 1) !default;
$screen-md-max:              ($screen-lg - 1) !default;

$desktop-only: "(min-width: #{$screen-lg})";
$mobile-only: "(max-width: #{$screen-md-max})";
$portrait-only: "(max-width: #{$screen-xs-max})";

$img-path: '../../../images/';
/*$systems: ('aqs', 'trimble');*/
/*$system: 'aqs/';*/
$system: 'aqs/';
$img-path-system: $img-path + 'system/'+$system;
$graph-not-available: '../../../../images/system/'+$system;

