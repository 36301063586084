// .MuiInputBase-input {
//   font-size: x-large !important;
// }

// .MuiInputBase-root {
//   line-height: inherit !important;
// }

.MuiTab-textColorPrimary.Mui-selected {
  font-weight: bold;
}
