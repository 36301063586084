// -------------------------------------------
//              Main Menu
// -------------------------------------------

@import '../../../styles/helpers';

.menu {
  flex: 1;
  margin-left: 4%;
  overflow-x: overlay;
  overflow-y: hidden;
  white-space: nowrap;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
    vertical-align: baseline;
  }

  a {
    text-decoration: none;
    display: inline-block;
    color: #666;
    margin: 0 $space_xs;
    text-align: center;
    font-family: Tahoma, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 15px;
    cursor: pointer;

    &:hover {
      color: #666 !important;
    }

    i {
      display: block;
      width: 61px;
      height: 63px;
      margin: 0px auto;
    }

    &.leaks {
      color: $color_blue;
      @include sprite(-229px, -420px, -65px, -504px, i);

      &.active {
        @include sprite(-163px, -418px, -332px, -503px, i);
      }
    }

    &.sensors {
      color: $color_brown;
      @include sprite(0, -504px, -133px, -502px, i);

      &.active {
        @include sprite(-265px, -504px, -200px, -504px, i);
      }
    }

    &.couples {
      color: $color_green;
      @include sprite(-379px, -425px, -307px, -353px, i);

      &.active {
        @include sprite(-309px, -427px, -376px, -357px, i);
      }
    }

    &.dashboard {
      color: $color_cyan;
      @include sprite(-507px, -10px, -507px, -73px, i);
    }

    &.leak-locator {
      color: $color_orange;
      @include sprite(-292px, -591px, -361px, -591px, i);

      &.active {
        @include sprite(-298px, -666px, -365px, -666px, i);
      }
    }

    &.manage {
      color: $color_purple;
      @include sprite(-797px, -12px, -796px, -78px, i);

      &.active {
        @include sprite(-879px, -12px, -879px, -78px, i);
      }
    }

    &.noise {
      &:hover {
        opacity: 0.8;
      }

      i {
        background: url('./../../../images/menus/noise-menu.png'), no-repeat;
        background-size: cover;
        background-position-y: 6px;
      }

      &.active {
        i {
          background: url('./../../../images/menus/noise-menu-selected.png'),
            no-repeat;
          background-size: cover;
        }
      }
    }

    &.mobile {
      &:hover {
        opacity: 0.8;
      }

      i {
        background: url('./../../../images/menus/mobile-menu.png'), no-repeat;
        background-size: cover;
        background-position-y: 6px;
      }

      &.active {
        i {
          background: url('./../../../images/menus/mobile-menu-selected.png'),
            no-repeat;
          background-size: cover;
        }
      }
    }

    &.install {
      i {
        background: url('./../../../images/menus/install menu item off.png'),
          no-repeat;
      }

      &.active {
        i {
          background: url('./../../../images/menus/install menu item on.png'),
            no-repeat;
        }
      }
    }

    &.inspect {
      color: #09a5a2;

      i {
        background: url('./../../../images/menus/inspect_menu_selected.png'),
          no-repeat;
      }

      &.active {
        i {
          background: url('./../../../images/menus/inspect_menu.png'), no-repeat;
        }
      }
    }

    &.g5Devices {
      cursor: pointer;

      i {
        background: url('./../../../images/menus/G5\ icon_unselected.png'),
          no-repeat;
        background-position-y: 6px;
      }

      &.active {
        i {
          background: url('./../../../images/menus/G5\ icon_selected.png'),
            no-repeat;
        }
      }

      &:hover {
        opacity: 0.8;
      }
    }

    &.reports,
    &.settings {
      i {
        width: 39px;
        height: 36px;
        margin-right: 2px;
        margin-bottom: -5px;
      }

      i,
      div {
        display: inline-block;
        vertical-align: bottom;
      }
    }

    &.reports {
      margin-left: 30px;
      @include sprite(-456px, -558px, -457px, -506px, i);
    }

    &.settings {
      margin-left: 10px;
      @include sprite(-105px, -456px, -5px, -453px, i);
    }
  }
}

.manage-menu {
  $size: 35px;
  position: absolute;
  width: 100%;
  // bottom: 6.7em;
  bottom: 2.5em;
  opacity: 0.6;
  pointer-events: none;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  .create-item {
    border: none;
    border-radius: $size;
    float: right;
    margin-bottom: -35px;
    opacity: 0.8;
    pointer-events: all;

    i:before {
      @include ion-icon('plus', $size);
      vertical-align: baseline;
    }

    &:hover {
      opacity: 1;
    }
  }

  &.mobile {
    bottom: 40px;
  }

  .btn-menu {
    margin-left: 8px;
    padding: 0px;
    width: 45px;
    height: 45px;
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
    z-index: 999;
    cursor: pointer;
    pointer-events: all;

    &.noise {
      left: 50px;
      font-size: xx-large;
      font-family: 'Times New Roman', Times, serif;
    }

    &.pressure {
      left: 100px;
      font-size: xx-large;
      font-family: 'Times New Roman', Times, serif;
    }

    &.valve {
      left: 150px;
      font-size: xx-large;
      font-family: 'Times New Roman', Times, serif;
    }

    &.alerts {
      background: #5bc0de url('./../../../images/alerts-context-icon.png')
        no-repeat;
      background-position: center;
      background-size: 40px;
    }

    &.projects {
      margin-left: 5px;

      i:before {
        @include ion-icon('earth', $size);
      }
    }

    &.SOPs {
      background: #5bc0de url('./../../../images/ic_hysrant_blank.png')
        no-repeat;
      background-position: center;
      background-size: 16px;
    }

    &.Sensors {
      pointer-events: auto;
      background: #5bc0de url('./../../../images/ic_sensor_blank.png') no-repeat;
      background-position: center;
      background-size: 38px;
    }

    &.interference {
      margin-left: 5px;
      background: #5bc0de url('./../../../images/ic_interference_blank.png')
        no-repeat;
      background-position: center;
      background-size: 38px;
    }

    &.users {
      margin-left: 5px;

      i:before {
        @include ion-icon('person-stalker', $size);
      }
    }

    &.customers {
      margin-left: 5px;

      i:before {
        @include ion-icon('ios-briefcase', $size);
      }
    }

    &.mobile-alerts {
      background-image: url('./../../../images/alerts-context-icon.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 40px;
    }

    &.mobile-tasks {
      background-image: url('./../../../images/mobile-survey-context.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 40px;
    }

    &.mobile-devices {
      background-image: url('./../../../images/mobile-device-control.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 40px;
    }
  }
}
