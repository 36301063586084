// -------------------------------------------
//            Starter Styles
// -------------------------------------------

@import "../../../../styles/helpers";

.yesNoBtn {
  outline: none !important;
  border: 0px !important;
  width: 24px !important;
  position: relative !important;
  top: -2px !important;
}

.boldHeader {
  font-weight: bold;
  width: 160px;
}

.downloadLogBtn {
  float: right;
  margin-right: 10px;
  margin-top: -30px;
}

.sample-info {
  position: absolute;
  max-height: 90%;
  bottom: 20px;
  left: 0;
  right: 0;
  padding: 25px 0 10px 0;
  background: #fff;
  box-shadow: 0 0 20px rgba(#000, .1);

  .graphLable {
    font-weight: bold;

    &.lableY {
      position: absolute;
      transform: rotate(-90deg);
      transform-origin: left top 0;
      top: 45%;
    }

    &.lableX {
      display: flex;
      margin-left: 50%;
      margin-top: -10%;
    }
  }

  .icon-close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 8px;
    z-index: 1;

    &:before {
      @include ion-icon("ios-close-empty", 36px, #333);
      line-height: 14px;
    }

    &:hover {
      cursor: pointer;
      background: rgba(#fff, .5);
    }
  }

  .downloads {
    float: left;
    position: relative;
    margin-right: 20px;
  }

  .btn-download {
    color: #333;

    &:before {
      @include ion-icon("volume-medium", 24px, #333);
      line-height: 14px;
      vertical-align: text-bottom;
      margin-right: 7px;
    }
  }

  table {
    margin: auto;
  }
  th, td {
    padding: 5px 10px;
    a {
      cursor: pointer;
    }
  }
}

.tabBtnOverMap {
  width: 80px;
  text-align: center;
  height: 30px;
  position: absolute;
  top: -30px;
  background: rgba(255, 255, 255, 0.8);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  cursor:pointer;
}

.tabSelected {
  border-bottom: 3px solid #54c3cb;
  padding-bottom: 5px;
}

.tabBtnOverMap:hover {
  color: #37ADB5;
}

.noiseTabBtn {
  margin-left: 10px;
}

.pressureTabBtn {
  margin-left: 100px;
}

.paddingTop7 {
  padding-top:7px;
}

.noise-alert-details {
  width: 280px;
  height: 220px;
  background-color: #eee;
  border: #000 solid 1px;
  padding: 0px 10px;

  h2 {
    text-align: center;
    text-decoration-line: underline;
  }

  table {
    width: 100%;
  }

  tr {
    padding: 3px !important;
    font-size: 16px;
  }

  th, td {
    padding: 3px;
  }
}

.reload-noises-parent {
  float: right;
  position: absolute;
  top: 0px;
  left: 210px;

  .input-field {
    margin: 1px 3px;
  }

  .btn-reload-noises {
    background-color: #5cb85c;
    border: 1px solid #4cae4c;
    color: white;
    font-weight: bold;
  }
}

.close-noise-parent {
  float: right;
  position: absolute;
  top: 0px;
  left: 80%;
  padding: 2px;

  .btn-close-noise {
    background-color: rgba(158, 206, 238, 0.90);
    border: 1px solid #337ab7;
    color: black;
    font-weight: bold;
  }
}

.grid-container {
  display: inline-grid;
  // grid-column-gap: 5px;
  grid-template-columns: auto auto auto;
  border: solid 1px #a7a7a7;
  margin: 0 5px;

  audio {
    width: 250px;
  }
}

.grid-item {
  padding: 5px;
  text-align: center;
  margin: auto;
}

.grid-container-bit-samples {
  width: 130%;
  display: inline-grid;
  grid-column-gap: 5px;
  grid-template-columns: auto auto auto auto;
  justify-content: space-evenly;
  border: solid 1px #a7a7a7;
  border-radius: 5px;
  margin: 0 5px;

  audio {
    width: 275px;
  }

}
