// -------------------------------------------
//            Alert Details Panel
// -------------------------------------------

@import '../../../styles/helpers';

.alert-details {
  @include panel(40%);
  position: relative;
  display: none;
  // overflow: auto;
  &.show {
    display: block;
  }

  h2 {
    font-weight: 100;
    margin: 0;
    padding: 8px 15px;
    float: left;
    font-size: 20px;
  }

  .actions {
    float: right;
    padding: 5px 12px;
    margin-left: 3%;
  }

  .icon-close {
    &:before {
      @include ion-icon('ios-close-empty', 26px, #333);
    }

    &:hover {
      cursor: pointer;
    }
  }

  .tooltip-cont {
    position: relative;
  }

  .tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    background: #fff;
    border: 1px solid $color_brown;
    display: block;
    z-index: 9;
    opacity: 1;
    padding: 5px 10px;
    margin-top: 9px;
    color: darken($color_brown, 10%);
    font-size: 0.9em;
    max-width: 100%;
    @include triangle(top, 15px, #fff, $color_brown);
  }

  .form-feedback {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover {
      white-space: normal;
    }
  }

  .form-actions {
    padding: 10px 15px 0;

    .form-feedback {
      float: left;
      margin-top: 6px;
      max-width: calc(100% - 70px);
    }

    .btn + .form-feedback {
      text-align: center;
      float: none;
      max-width: calc(100% - 170px);
    }

    .btn-submit {
      float: right;
    }

    .btn-order {
      float: left;
      margin: 0 6px;
    }

    .btn-wav-download {
      float: left;
      color: #333;
      margin-right: 25px;

      &:before {
        @include ion-icon('volume-medium', 24px, #333);
        line-height: 14px;
        vertical-align: text-bottom;
        margin-right: 7px;
      }
    }
  }

  .sticky-actions {
    padding-bottom: 150px;
    height: 100%;
    overflow: auto;

    .form-actions {
      padding: 10px 15px;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      border-top: 1px solid #ddd;
      background: rgba(#eee, 0.8);
    }
  }
}

.mobile-details-tab.sticky-actions {
  padding: 20px;
}

#couples-tab {
  .comment-area {
    padding: 5px;
    margin-top: 22px;
  }
}
