// -------------------------------------------
//                   Header
// -------------------------------------------

@import '../../../styles/helpers';

.header {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  background-color: white;
  background-size: contain;
  box-shadow: 0 0 5px rgba(#000, 0.5);
  min-height: 90px;
  padding: $space_xs $space_sm;

  &.custom {
    background: url($img-path + 'powerd-by.png') no-repeat right top #fff;
  }
}

.header-icon {
  img {
    display: block;
    margin: auto;
  }

  .version {
    margin-top: 5px;
    line-height: 1;
    color: $color_cyan;
    font-weight: bold;
    font-size: smaller;
  }
}

.logo {
  // float: left;
  margin-top: 10px;
  a {
    @include ir('../../images/system/' + $system + 'logo.png', 189px, 53px);
  }
}

.menu li {
  // This duplicates stuff from Menu.scss
  // For some reason this is being applied earlier.
  // Putting this here prevents FOUC and issues with the map caused by this unstyled content.
  display: inline-block;
  a {
    text-decoration: none;
    display: inline-block;
    color: #666;
    margin: 0 $space_xs;
    text-align: center;

    font-family: Tahoma, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 15px;
  }
}
