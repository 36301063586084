@import "../../../styles/helpers";
@import "../../../styles/variables";

// ToDo: Page.scss and Login.scss are always loaded

#app,
[data-reactroot],
.page-container {
  // display: inline;
  position: absolute;
  height: 100%;
  width: 100%;
}

.login-page {
  min-height: 600px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;

  .main {
    min-height: 520px;
    padding-top: 45px;
    margin: auto 0;
  }

  .registered {
    color: #fff;
    text-align: center;
    font-family: Tahoma;
    font-size: 12px;
    margin-top: 8px;
  }

  footer {
    text-align: center;
    font-size: 12px;
  }

  .copyright {
    &:before {
      content: url($img-path-system + 'icon-logo.png');
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }
  }

  .design {
    margin-bottom: 15px;
  }

  .sweet-loading {
    position: relative;
    width: fit-content;
    bottom: 50%;
  }

  .back-to-login-btn {
    color: #37ADB5;
    font-size: 13px;
    text-decoration: underline;
    display: inline-block;
    text-align: center;
    width: 100%;
  }
}

.login-form {
  position: relative;
  background: #fff;
  width: 350px;
  height: 390px;
  border-radius: 20px;
  margin: auto;
  padding: 25px 35px;
  header {
    @include clear;
    padding-bottom: 15px;
    border-bottom: 1px solid $color_cyan;
  }
  .logo,
  .version {
    float: none;
    display: inline-block;
    vertical-align: bottom;
  }
  .version {
    padding-left: 13px;
    margin-left: 11px;
    border-left: 1px solid $color_cyan;
    padding-top: 15px;
    color: $color_cyan;
    font-size: 15px;
    line-height: 20px;
  }
  .control-label {
    font-weight: normal;
    text-align: left;
    padding-top: 0;
    line-height: 22px;
    font-size: 12px;
    color: #777;
  }
  .login-field {
    padding-left: 10px;
    input[type="text"],
    input[type="password"] {
      width: 100%;
      box-shadow: none;
    }
  }
  .form-control {
    $height: 22px;
    height: $height;
    padding: 0 8px;
    line-height: $height;
    box-shadow: none;
    border-radius: 0;
    border: 1px solid $color_cyan;
  }
  .forgot-link {
    color: $color_cyan;
    font-size: 11px;
    text-decoration: underline;
    margin-top: 4px;
    display: inline-block;
  }
  .form-remember {
    margin-top: 25px;
    margin-bottom: 10px;
    .control-label {
      color: #333;
    }
  }
  .form-feedback {
    margin-bottom: 10px;
    height: 16px;
    .login-field {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .login-submit {
    padding: 2px 10px 1px;
    border-radius: 5px;
    font-size: 13px;
    line-height: 18px;
    color: #fef4e9;
    border: solid 1px #da7c0c;
    background: #f78d1d;
    background: linear-gradient(to bottom, #faa51a, #f47a20);
    text-shadow: 0 1px 1px rgba(0,0,0,.3);
    &:hover {
      background: #f47c20;
      background: linear-gradient(to bottom, #f88e11, #f06015);
    }
    &:active,
    &:focus {
      color: #fcd3a5;
      background: linear-gradient(to bottom, #f47a20, #faa51a);
      box-shadow: none;
    }
  }

  .tooltip-cont {
    position: relative;
  }
  .tooltip {
    position: absolute;
    top: 50%;
    left: 100%;
    background: #fff;
    border: 1px solid $color_brown;
    display: block;
    z-index: 9;
    opacity: 1;
    padding: 5px 10px;
    margin-left: 15px;
    color: darken($color_brown, 10%);
    font-size: .9em;
    margin-top: -13px;
    white-space: nowrap;
    @include triangle(left, 12px, #fff, $color_brown);
  }

  footer {
    // position: absolute;
    bottom: -15px;
  }
}

.password-form {
  .login-submit {
    padding: 2px 5px 1px;
    font-size: 12px;
  }
}

.login-input-field {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 15px 0 0 0;

  label {
    font-weight: normal;
  }

  p {
    color: red;
    position: absolute;
    bottom: -60%;
  }
}

.form-actions {
  margin: 15px 0;
}
