@import "../../../styles/helpers";

@include ion-font();

body {
  margin: 0 !important;
  font: $font;
  // color: $color_text;
  background-color: $color_background !important;
  // Not responsive on 1st phase
}

.leaks-page {
  min-width: 1400px;
  .main {
    padding: 5px;
    position: relative;
  }
}

.page .main {
  opacity: 1;
  transition: opacity .2s;
  &.fetching {
    opacity: .5;
    pointer-events: none;
  }
}
