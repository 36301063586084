// -------------------------------------------
//            Details Tab Styles
// -------------------------------------------

@import "../../../../styles/helpers";

.form-file-upload {
  padding-top: 20px;
  @include clear;
}
.files-tab-files {
  padding: 20px 15px 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: #eee;
  @include clear;
}
.files-tab-empty {
  width: 100%;
  text-align: center;
  color: #777;
  padding-bottom: 20px;
}
.uploader {
  margin: 10px;
}

.upload-progress {
  background-color: lightcyan;
  padding: 10px;
  margin: 5px;
  border: cadetblue solid 1px;
  border-radius: 10px;
}
.file-item {
  width: 17%;
  margin: 0 1% 20px;
  position: relative;
  text-align: center;
  overflow-wrap: break-word;

  .file-name {
    color: #666;
    font-size: 12px;
    padding-top: 5px;
  }

  .icon-delete {
    $size: 30px;
    position: absolute;
    top: -12px;
    right: 10px;
    background: #666;
    border-radius: $size;
    width: $size;
    height: $size;
    line-height: $size - 2;
    text-align: center;
    box-shadow: 1px 1px 3px rgba(#000, .3);
    border: none;
    padding: 0;
    opacity: 0;
    transition: all .2s;

    &:before {
      @include ion-icon("trash-a", 20px, #fff);
    }

    &:hover {
      cursor: pointer;
      background: #444;
    }
  }

  &:hover {
    .icon-delete {
      opacity: .9;
    }
  }
}
