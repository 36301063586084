// -------------------------------------------
//            Legend Panel
// -------------------------------------------

@import '../../../styles/helpers';

.map-legend {
  position: absolute;
  right: 0;
  top: 80px;
  z-index: 1;
  background: #fff;
  width: 90%;
  height: 80%;
  max-width: 95%;
  box-shadow: rgba(#000, 0.4) 0px 1px 4px -1px;
  padding: 0 25px;
  transform: translateX(100%);
  transition: transform 0.25s ease-in-out;

  &.show {
    transform: translateX(0);
  }

  .tab {
    width: 100%;
    height: 86%;
    float: left;
    display: inline-block;
    margin: 0px 5px;
    padding: 10px 15px;
    border: 1px solid #aaa;
    border-radius: 5px;
  }

  .actions {
    background: #fff;
    position: absolute;
    top: 0;
    right: 100%;
    box-shadow: rgba(#000, 0.4) 0px 1px 4px -1px;
    min-height: 32px;
    min-width: 32px;

    &:after {
      content: '';
      position: absolute;
      background: #fff;
      top: 0;
      bottom: 0;
      left: 100%;
      width: 10px;
    }
  }

  .icon-toggle {
    padding: 5px 8px 5px 6px;
    display: block;

    &:before {
      @include ion-icon('ios-help-outline', 22px, #333);
    }

    &:hover {
      cursor: pointer;
    }
  }

  &.show .icon-toggle {
    padding: 5px 6px 5px 8px;

    &:before {
      content: '\F404';
    }
  }

  h2 {
    font-weight: 100;
    margin: 0;
    padding: 25px 0 15px;
    text-align: center;
  }

  form {
    padding: 10px 0;
  }

  label {
    font-weight: normal;
    padding-left: 10px;
  }

  .table-row {
    border-width: 3px 10px;
    height: 41px;

    &:nth-child(even) {
      background-color: #f2f2f2;
    }

    &:hover {
      background-color: rgba(91, 192, 222, 0.5);
    }

    td {
      min-width: 65px;
    }

    th {
      padding: 0px 10px;
      font-size: small;
    }
  }

  .installation-table-box {
    display: flex;

    // :nth-child(2n) {
    //   padding-top: 15px;
    // }
  }
  .installation-table-col {
    display: flex;
    flex-direction: column;
    margin: 20px;
  }

  thead {
    min-height: 25px;
    margin-left: 65px;
  }
  tbody {
    margin-top: 20px;
  }

  th {
    text-align: center;
    padding: 0 5px;
  }

  td {
    padding: 5px;
  }

  .alert {
    height: 50px !important;
    width: 40px;
    background-color: rgba(255, 255, 255, 0);
    background-position: center center;
    background-repeat: no-repeat;
    /* Open: */
    &.open {
      &.a {
        background-image: url('../../../images/map/alerts/alert-a-open.png');
      }

      &.b {
        background-image: url('../../../images/map/alerts/alert-b-open.png');
      }

      &.c {
        background-image: url('../../../images/map/alerts/alert-c-open.png');
      }
    }
    /* Close: */
    &.close-group {
      &.a {
        background-image: url('../../../images/map/alerts/alert-a-closed.png');
      }

      &.b {
        background-image: url('../../../images/map/alerts/alert-b-closed.png');
      }

      &.c {
        background-image: url('../../../images/map/alerts/alert-c-closed.png');
      }
    }

    &.artifact {
      background-image: url('../../../images/map/alerts/alert-artifact.png');
    }

    &.shadow {
      background-image: url('../../../images/map/alerts/alert-shadow.png');
    }

    &.false-path {
      background-image: url('../../../images/map/alerts/alert-falsepath.png');
    }

    &.irrigation {
      background-image: url('../../../images/map/alerts/alert-irrigation.png');
    }
  }

  .leak-couples-sensor {
    height: 50px;
    width: 34px;
    background-color: rgba(255, 255, 255, 0);
    background-position: center center;
    background-repeat: no-repeat;

    &.first {
      background-image: url('../../../images/map/sensors/couple0.png');
    }

    &.second {
      background-image: url('../../../images/map/sensors/couple1.png');
    }
  }

  .general-pipes-legend {
    float: left;
    padding: 10px 0;
    border: #aaa 1px solid;
    border-radius: 10px;
    margin: 0 10px;
  }

  .pipe-topic {
    float: left;
    margin-right: 40px;
    text-align: center;
  }

  .pipe-color {
    width: 80;
    height: 10px;

    &.metal {
      background: #0000ff;
    }

    &.pvc {
      background: #00ff00;
    }

    &.azbest-cement {
      background: #ff00ff;
    }

    &.poly-ethelen {
      background: #0c9100;
    }

    &.mdpe {
      background: #ffff00;
    }

    &.concrete {
      background: #8f2f41;
    }

    &.cast-iron {
      background: #00ccff;
    }

    &.brass {
      background: #ff9900;
    }

    &.wood {
      background: #542700;
    }

    &.wall {
      background: #660000;
    }

    &.ductile-iron {
      background: #7808af;
    }

    &.copper {
      background: #ff0000;
    }

    &.lead-type {
      background: #ada211;
    }

    &.large-diameter {
      background: #000099;
    }

    &.other {
      background: #000000;
    }
  }

  .sop {
    width: 60px;
    background-color: rgba(255, 255, 255, 0);
    background-size: 35px 35px;
    background-position: center center;
    background-repeat: no-repeat;

    &.marker-undefined {
      background-image: url('../../../images/map/sop/default/ic_sop_undefined.png');
    }

    &.marker-hydrant {
      background-image: url('../../../images/map/sop/default/ic_sop_hydrant.png');
    }

    &.marker-valve {
      background-image: url('../../../images/map/sop/default/ic_sop_valve.png');
    }

    &.marker-gate_valve {
      background-image: url('../../../images/map/sop/default/ic_sop_gate_valve.png');
    }

    &.marker-control_valve {
      background-image: url('../../../images/map/sop/default/ic_sop_control_valve.png');
    }

    &.marker-service_valve {
      background-image: url('../../../images/map/sop/default/ic_sop_service_valve.png');
    }

    &.marker-manhole {
      background-image: url('../../../images/map/sop/default/ic_sop_manhole.png');
    }

    &.marker-fitting {
      background-image: url('../../../images/map/sop/default/ic_sop_fitting.png');
    }

    &.underground {
      &.undefined {
        background-image: url('../../../images/map/sop/default/underground/ic_sop_undefined.png');
      }

      &.hydrant {
        background-image: url('../../../images/map/sop/default/underground/ic_sop_hydrant.png');
      }

      &.valve {
        background-image: url('../../../images/map/sop/default/underground/ic_sop_valve.png');
      }

      &.gate_valve {
        background-image: url('../../../images/map/sop/default/underground/ic_sop_gate_valve.png');
      }

      &.control_valve {
        background-image: url('../../../images/map/sop/default/underground/ic_sop_control_valve.png');
      }

      &.service_valve {
        background-image: url('../../../images/map/sop/default/underground/ic_sop_service_valve.png');
      }

      &.manhole {
        background-image: url('../../../images/map/sop/default/underground/ic_sop_manhole.png');
      }

      &.fitting {
        background-image: url('../../../images/map/sop/default/underground/ic_sop_fitting.png');
      }
    }

    &.special {
      &.fountain {
        background-image: url('../../../images/map/sop/default/ic_sop_fountain.png');
      }
      &.pump {
        background-image: url('../../../images/map/sop/default/ic_sop_pump.png');
      }
      &.prv {
        background-image: url('../../../images/map/sop/default/ic_sop_prv.png');
      }
      &.washout {
        background-image: url('../../../images/map/sop/default/ic_sop_washout.png');
      }
      &.watering {
        background-image: url('../../../images/map/sop/default/ic_sop_watering.png');
      }
      &.air_valve {
        background-image: url('../../../images/map/sop/default/ic_sop_air_valve.png');
      }
    }

    // &.good-background {
    //   background-image: url('../../../images/map/sop/good_background.png');
    // }

    // &.bad-background {
    //   background-image: url('../../../images/map/sop/bad_background.png');
    // }
  }

  .sensor {
    background-color: rgba(255, 255, 255, 0);
    background-position: center center;
    background-repeat: no-repeat;
    /* Active: */
    &.installed {
      &.above {
        &.accmtr {
          &.gps {
            background-image: url('../../../images/map/sensors/sensor-installed-accmtr-above-gps.png');
          }

          &.rad {
            background-image: url('../../../images/map/sensors/sensor-installed-accmtr-above-radio.png');
          }
        }

        &.hdrpn {
          &.gps {
            background-image: url('../../../images/map/sensors/sensor-installed-hdrpn-above-gps.png');
          }

          &.rad {
            background-image: url('../../../images/map/sensors/sensor-installed-hdrpn-above-radio.png');
          }
        }
      }

      &.under {
        &.accmtr {
          &.gps {
            background-image: url('../../../images/map/sensors/sensor-installed-accmtr-under-gps.png');
          }

          &.rad {
            background-image: url('../../../images/map/sensors/sensor-installed-accmtr-under-radio.png');
          }
        }

        &.hdrpn {
          &.gps {
            background-image: url('../../../images/map/sensors/sensor-installed-hdrpn-under-gps.png');
          }

          &.rad {
            background-image: url('../../../images/map/sensors/sensor-installed-hdrpn-under-radio.png');
          }
        }
      }
    }
    /* Ready: */
    &.ready {
      &.above {
        &.accmtr {
          &.gps {
            background-image: url('../../../images/map/sensors/sensor-ready-accmtr-above-gps.png');
          }

          &.rad {
            background-image: url('../../../images/map/sensors/sensor-ready-accmtr-above-radio.png');
          }
        }

        &.hdrpn {
          &.gps {
            background-image: url('../../../images/map/sensors/sensor-ready-hdrpn-above-gps.png');
          }

          &.rad {
            background-image: url('../../../images/map/sensors/sensor-ready-hdrpn-above-radio.png');
          }
        }
      }

      &.under {
        &.accmtr {
          &.gps {
            background-image: url('../../../images/map/sensors/sensor-ready-accmtr-under-gps.png');
          }

          &.rad {
            background-image: url('../../../images/map/sensors/sensor-ready-accmtr-under-radio.png');
          }
        }

        &.hdrpn {
          &.gps {
            background-image: url('../../../images/map/sensors/sensor-ready-hdrpn-under-gps.png');
          }

          &.rad {
            background-image: url('../../../images/map/sensors/sensor-ready-hdrpn-under-radio.png');
          }
        }
      }
    }
    /* Not Active: */
    &.inactive {
      &.above {
        &.accmtr {
          &.gps {
            background-image: url('../../../images/map/sensors/sensor-inactive-accmtr-above-gps.png');
          }

          &.rad {
            background-image: url('../../../images/map/sensors/sensor-inactive-accmtr-above-radio.png');
          }
        }

        &.hdrpn {
          &.gps {
            background-image: url('../../../images/map/sensors/sensor-inactive-hdrpn-above-gps.png');
          }

          &.rad {
            background-image: url('../../../images/map/sensors/sensor-inactive-hdrpn-above-radio.png');
          }
        }
      }

      &.under {
        &.accmtr {
          &.gps {
            background-image: url('../../../images/map/sensors/sensor-inactive-accmtr-under-gps.png');
          }

          &.rad {
            background-image: url('../../../images/map/sensors/sensor-inactive-accmtr-under-radio.png');
          }
        }

        &.hdrpn {
          &.gps {
            background-image: url('../../../images/map/sensors/sensor-inactive-hdrpn-under-gps.png');
          }

          &.rad {
            background-image: url('../../../images/map/sensors/sensor-inactive-hdrpn-under-radio.png');
          }
        }
      }
    }
    /* Not installed:*/
    &.uninstalled {
      &.above {
        background-image: url('../../../images/map/sensors/sensor-uninstalled_above.png');
      }

      &.under {
        background-image: url('../../../images/map/sensors/sensor-uninstalled_under.png');
      }
    }
  }

  .installed-accmtr-above-gps {
  }
}
