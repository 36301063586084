// -------------------------------------------
//              Table Actions
// -------------------------------------------

@import '../../../styles/helpers';

.dates-filter {
  line-height: initial;
  color: initial;
  text-align: right;

  label {
    color: white;
  }
}
.table-actions {
  float: left;
  padding-left: 5px;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  button {
    padding: 2px 8px;
  }

  .table-actions-print:after {
    @include ion-icon('ios-printer-outline', 20px, #000);
  }

  .table-actions-export {
    &.history {
      &:after {
        @include ion-icon('ios-list-outline', 20px, #000);
        color: white;
        font-weight: bold;
        font-size: 30px;
        margin-top: -5px;
      }
    }

    &:after {
      @include ion-icon('ios-list-outline', 20px, #000);
    }
  }

  .table-actions-export-all:after {
    @include ion-icon('ios-albums-outline', 20px, #000);
  }

  .table-actions-export-alerts-history:after {
    @include ion-icon('ios-paper-outline', 20px, #000);
  }

  .table-actions-export-sensors-maintenance:after {
    @include ion-icon('wrench', 20px, #000);
  }

  .table-actions-export-auth-google:after {
    @include ion-icon('social-google', 20px, #000);
  }
  .table-actions-export-google-cloud:after {
    @include ion-icon('ios-download', 20px, #000);
  }
}
