@import "../../../styles/helpers";


.main-data-area {
   box-sizing: border-box;
   width: 39.5%;
   float: left;
   margin: 5px 0.25%;
   background: #fff;
   border-radius: 8px;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
   position: relative;
}

.pvtUi {
   color: #2a3f5f;
   font-family: Verdana;
   border-collapse: collapse;
   width: 99%;
   height: 99%;
   margin: auto;
}
.pvtUi select {
   user-select: none;
   -webkit-user-select: none;
   -moz-user-select: none;
   -khtml-user-select: none;
   -ms-user-select: none;
}

.pvtUi td.pvtOutput {
   vertical-align: top;
}

table.pvtTable {
   width: 100%;
   font-size: 8pt;
   text-align: left;
   border-collapse: collapse;
   margin-top: 3px;
   margin-left: 3px;
   font-family: Verdana;

   tbody {
      max-height: 540px;
      overflow: auto;
   }
}

.analysis-pivottable {
   height: 660px;
   overflow: auto;
}

// table.pvtTable tbody tr:hover th{
//    background-color: #ffff66 !important;
// }

// table.pvtTable tbody tr:hover td{
//    background-color: #ffff66 !important;
// }

table.pvtTable thead tr th,
table.pvtTable tbody tr th {
   background-color: #ebf0f8;
   border: 1px solid #c8d4e3;
   font-size: 8pt;
   padding: 5px;
}

table.pvtTable .pvtColLabel {
   text-align: center;
}
table.pvtTable .pvtTotalLabel {
   text-align: right;
}

table.pvtTable tbody tr td {
   color: #2a3f5f;
   padding: 5px;
   background-color: #fff;
   border: 1px solid #c8d4e3;
   vertical-align: top;
   text-align: right;
}

.pvtVal {
   &:hover {
      background-color: #d9e9ff !important;
   }
}

.pvtTotal,
.pvtGrandTotal {
   font-weight: bold;

   &:hover {
      background-color: #d9e9ff !important;
   }
}

.pvtRowOrder,
.pvtColOrder {
   cursor: pointer;
   width: 15px;
   margin-left: 5px;
   display: inline-block;
   user-select: none;
   text-decoration: none !important;
   -webkit-user-select: none;
   -moz-user-select: none;
   -khtml-user-select: none;
   -ms-user-select: none;
}

.pvtAxisContainer,
.pvtVals {
   border: 1px solid #a2b1c6;
   background: #f2f5fa;
   padding: 5px;
   min-width: 20px;
   min-height: 20px;
}

.pvtRenderers {
   padding-left: 5px;
   user-select: none;
   height: 30px;

   .pvtDropdownCurrent {
      width: 200px;
   }
}

.pvtDropdown {
   display: inline-block;
   position: relative;
   -webkit-user-select: none;
   -moz-user-select: none;
   -khtml-user-select: none;
   -ms-user-select: none;
   margin: 3px;
}

.pvtDropdownIcon {
   float: right;
   color: #a2b1c6;
}
.pvtDropdownCurrent {
   text-align: left;
   border: 1px solid #a2b1c6;
   border-radius: 4px;
   display: inline-block;
   position: relative;
   width: 100px;
   box-sizing: border-box;
   background: white;
   text-overflow: ellipsis;
   overflow: hidden;
   white-space: nowrap;

   &:hover {
      background-color: white !important;
   }

}

.pvtDropdownCurrentOpen {
   border-radius: 4px 4px 0 0;
}

.pvtDropdownMenu {
   background: white;
   position: absolute;
   display: table;
   width: 100%;
   margin-top: -1px;
   border-radius: 0 0 4px 4px;
   border: 1px solid #a2b1c6;
   border-top: 1px solid #dfe8f3;
   box-sizing: border-box;
}

.pvtDropdownValue {
   padding: 2px 5px;
   font-size: 12px;
   text-align: left;
   &:hover {
      background-color: #d9e9ff;
   }
}
.pvtDropdownActiveValue {
   background: #ebf0f8;
}

.pvtVals {
   text-align: center;
   white-space: nowrap;
   vertical-align: top;
   padding-bottom: 12px;
   width: 160px;
   height: 30px;
}

.pvtRows {
   height: 35px;
}

.pvtAxisContainer li {
   padding: 8px 6px;
   list-style-type: none;
   cursor: move;
}
.pvtAxisContainer li.pvtPlaceholder {
   -webkit-border-radius: 5px;
   padding: 3px 15px;
   -moz-border-radius: 5px;
   border-radius: 5px;
   border: 1px dashed #a2b1c6;
}
.pvtAxisContainer li.pvtPlaceholder span.pvtAttr {
   display: none;
}

.pvtAxisContainer li span.pvtAttr {
   -webkit-text-size-adjust: 100%;
   background: #f3f6fa;
   border: 1px solid #c8d4e3;
   padding: 2px 5px;
   white-space: nowrap;
   -webkit-border-radius: 5px;
   -moz-border-radius: 5px;
   border-radius: 5px;
   user-select: none;
   -webkit-user-select: none;
   -moz-user-select: none;
   -khtml-user-select: none;
   -ms-user-select: none;
}

.pvtTriangle {
   cursor: pointer;
   color: #506784;
}

.pvtHorizList li {
   display: inline-block;
}
.pvtVertList {
   vertical-align: top;
}

.pvtFilteredAttribute {
   font-style: italic;
}

.sortable-chosen .pvtFilterBox {
   display: none !important;
}

.pvtCloseX {
   position: absolute;
   right: 5px;
   top: 5px;
   font-size: 18px;
   cursor: pointer;
   text-decoration: none !important;
}

.pvtDragHandle {
   position: absolute;
   left: 5px;
   top: 5px;
   font-size: 18px;
   cursor: move;
   color: #a2b1c6;
}

.pvtButton {
   color: #506784;
   border-radius: 5px;
   padding: 3px 6px;
   background: #f2f5fa;
   border: 1px solid;
   border-color: #c8d4e3;
   font-size: 14px;
   margin: 3px;
   transition: 0.34s all cubic-bezier(0.19, 1, 0.22, 1);
   text-decoration: none !important;
}

.pvtButton:hover {
   background: #e2e8f0;
   border-color: #a2b1c6;
}

.pvtButton:active {
   background: #d1dae6;
}

.pvtFilterBox input {
   border: 1px solid #c8d4e3;
   border-radius: 5px;
   color: #506784;
   padding: 0 3px;
   font-size: 14px;
}

.pvtFilterBox input:focus {
   border-color: #119dff;
   outline: none;
}

.pvtFilterBox {
   z-index: 100;
   width: 300px;
   border: 1px solid #506784;
   background-color: #fff;
   position: absolute;
   text-align: center;
   user-select: none;
   min-height: 100px;
   -webkit-user-select: none;
   -moz-user-select: none;
   -khtml-user-select: none;
   -ms-user-select: none;
}

.pvtFilterBox h4 {
   margin: 15px;
}
.pvtFilterBox p {
   margin: 10px auto;
}
.pvtFilterBox button {
   color: #2a3f5f;
}
.pvtFilterBox input[type='text'] {
   width: 230px;
   color: #2a3f5f;
   margin-bottom: 5px;
}

.pvtCheckContainer {
   text-align: left;
   font-size: 14px;
   white-space: nowrap;
   overflow-y: scroll;
   width: 100%;
   max-height: 30vh;
   border-top: 1px solid #dfe8f3;
}

.pvtCheckContainer p {
   margin: 0;
   margin-bottom: 1px;
   padding: 3px;
   cursor: default;
}

.pvtCheckContainer p.selected {
   background: #ebf0f8;
}

.pvtOnly {
   display: none;
   width: 35px;
   float: left;
   font-size: 12px;
   padding-left: 5px;
   cursor: pointer;
}

.pvtOnlySpacer {
   display: block;
   width: 35px;
   float: left;
}

.pvtCheckContainer p:hover .pvtOnly {
   display: block;
}
.pvtCheckContainer p:hover .pvtOnlySpacer {
   display: none;
}

.pvtRendererArea {
   padding: 5px;
}

// .icon-pca {
//    &:before {
//      @include ion-icon("ios-information-circle", 26px, rgb(0, 107, 57));
//    }
//    &:hover {
//      cursor: pointer;
//    }
//  }

.icon-pca {
   &:before {
      margin-top: -5px;
     @include ion-icon("information-circled", 22px, rgb(0, 114, 25));
   }
   &:hover {
     cursor: pointer;
   }
 }

 .icon-leak-growth {
  &:before {
      margin-top: -5px;
    @include ion-icon("podium", 22px, rgb(0, 114, 25));
  }
  &:hover {
    cursor: pointer;
  }

  &.manual {
    &:before {
      margin-top: -5px;
    @include ion-icon("podium", 22px, cornflowerblue);
  }
  &:hover {
    cursor: pointer;
  }
  }
 }

 .size-buttons {
   display: inline-block;
   position: absolute;
   top: 13px;
   right: 15px;
   color: black;
 }

 .flot-drag {
   display: inline-flex;
   background-color: #bbe3fa;
   color: white;
   padding: 10px;
   border: solid 1px #aaa;
   border-radius: 15px;
   z-index: 999;
   box-shadow: 4px -4px 5px 1px #737373;


   &.collapse {
      height: 55px !important;
      min-width: 350px !important;
   }

   &.expand {
      width: 1435px !important;
      height: 800px !important;
   }
 }

 .dragHandler {
    width: 100%;
    background-color: #1487ae;
    min-height: 10px;
    color: white;
    padding: 4px;
    text-align: center;
    cursor: move;

    p {
      margin: 4px 10px;
    }
 }

 .dragContent {
  &.hidden {
    display: none !important;
  }
 }

 .analysis-heatmap {
   overflow: auto;
   max-height: 245px;
   display: flex;

   &div {
      width: 65px;
   }

   &.hidden {
      height: 0 !important;
   }
 }

 .analysis-plot {
   margin-left: 40px;

   &.hidden {
      display: none !important;
   }
 }

 .analysis-mode-segmented-controll {
   width: 70% !important;
   background-color: white;
   margin: 5px auto !important;
   display: block !important;
   transition-duration: 0.25s !important;
 }

 .dashboard-chart {
   margin: auto;
   display: block;

   &.hidden {
      display: none !important;
   }
 }

 .table-cell-class {
    width: inherit;
    height: inherit;
    color: black;
    .number {
      margin: auto;
    }
 }
