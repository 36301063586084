// -------------------------------------------
//           Header User Menu
// -------------------------------------------

@import '../../../styles/helpers';

.userblock {
  // position: absolute;
  // top: 5px;
  // right: 130px;
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 300;
  color: #666;
  > span {
    border-right: 2px solid #37b0b5;
    display: inline-block;
    vertical-align: middle;
    line-height: 20px;
    padding: 0 10px;
    &:last-child {
      border-right: none;
    }
  }
  a {
    text-decoration: none;
    display: inline-block;
    color: #666;
    margin: 0 $space_xs;
    text-align: center;
    cursor: pointer;
  }
}
