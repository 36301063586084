@import '../../../styles/helpers';

.mobile-context-buttons {
  position: absolute;
  bottom: 50px;
  left: 10px;
}

.mobile-sample-details {
  display: grid;
  justify-items: stretch;
  align-content: center;
  grid-template-columns: auto auto;
  column-gap: 30px;
}

.mobile-sample-form {
  display: grid;
  grid-template-columns: 70px auto;
  column-gap: 20px;
  row-gap: 5px;

  input[type='submit'] {
    width: 100%;
    background-color: aquamarine;
  }
}

.sample-details-container {
  padding: 10px;
  display: grid;
  grid-template-rows: auto auto auto;
  justify-content: center;
  row-gap: 10px;
}

.sample-audio {
  width: 500px;
  border: 1px solid #b8b8b8;
  border-radius: 5px;

  strong {
    padding: 5px;
  }

  audio {
    width: -webkit-fill-available;
  }

  .player {
    display: grid;
    grid-template-columns: auto auto;
  }
}

.mobile-samples-bottom {
  padding-inline: 10px;
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.add-mobile-alert-form {
  background: #fff;
  border-radius: $radius_md;
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  opacity: 0;
  visibility: visible;
  transform: scale(0.9);
  transition: visibility 0.25s, opacity 0.25s, transform 0.25s;
  display: none;

  h2 {
    font-weight: bold;
  }

  &.show {
    transition: visibility 0 0.25s, opacity 0.25s 0, transform 0.25s 0;
    transform: scale(1);
    opacity: 1;
    display: block;
  }

  .icon-close {
    &:before {
      @include ion-icon('ios-close-empty', 26px, #333);
    }
    &:hover {
      cursor: pointer;
    }
  }

  .new-alert-header {
    text-align: center;
    padding: 10px 0px;
  }

  fieldset {
    padding: 0 20px;
  }

  .tooltip-cont {
    position: relative;
  }

  .tooltip {
    position: absolute;
    top: 80%;
    left: 0;
    background: #fff;
    border: 1px solid $color_brown;
    display: block;
    z-index: 9;
    opacity: 1;
    padding: 5px 10px;
    margin-top: 9px;
    color: darken($color_brown, 10%);
    font-size: 0.9em;
    max-width: 100%;
    @include triangle(top, 15px, #fff, $color_brown);
  }

  .auto-center {
    margin: 0 auto 15px;
  }

  .form-actions {
    padding: 10px 15px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    // box-shadow:  0 0 5px rgba(#000, .2);
    border-top: 1px solid #ddd;
    background: rgba(#eee, 0.8);

    .btn {
      margin: 0 5px;
    }
  }
}

.btn-assessment.create-item {
  z-index: 300;
}
