@import "../../../styles/helpers";

$size: 35px;

.create-item {
  border: none;
  border-radius: $size;
  position: absolute;
  right: 10px;
  bottom: 40px;
  opacity: 0.65;
  width: $size;
  height: $size;

  i:before {
    @include ion-icon("plus", $size);
    vertical-align: baseline;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.35;
  }
}

.noise-to-alert {
  border: none;
  border-radius: 35px;
  position: absolute;
  right: 60px;
  bottom: 40px;
  opacity: 0.65;

  i:before {
    padding: 5px;
    @include ion-icon("waterdrop", $size * 0.75);
    vertical-align: baseline;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.35;
  }
}
