@import '../../../styles/helpers';
@import '../../../styles/variables';

.loading-page {
  // min-height: 600px;
  height: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  background-color: #fff;

  .loading-screen {
    min-height: 450px;
    padding-top: 45px;
    margin: auto 0;
    // width: 100%; // Ensure the container takes full width
    // height: 100%; // Ensure the container takes full height
  }
}

.MuiLinearProgress-root {
  top: -45px;
}
