// -------------------------------------------
//            AddressField Styles
// -------------------------------------------

@import "../../../styles/helpers";

.address-area {
  display: flex;

  .getAddress-button {
    background: #ffffff;
    margin: 1px auto 1px auto;
    position: absolute;
    right: 18px;
    top: 3px;
    border-radius: 4px;
    padding: 1px;
    opacity: 0.6;
  }

  .getAddress-button:hover {
    opacity: 1.0;
  }

  .getAddress-button:active {
    background-color: #e9e2e2;
  }
}
