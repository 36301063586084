// -------------------------------------------
//                   Alerts
// -------------------------------------------

@import '../../../styles/helpers';

.cell {
  text-align: center;
  width: inherit;
}

.alerts-wrp {
  @include panel(40%);
  position: relative;
  min-height: 30em;

  h2 {
    font-weight: 100;
    margin: 0;
    padding: 7px 15px;
  }

  .actions {
    float: right;
    padding: 5px 12px;
    margin: 4px 2px;
  }

  .icon-settings {
    &:before {
      @include ion-icon('funnel', 26px, #333);
    }

    &:hover {
      cursor: pointer;
    }
  }

  .icon-refresh {
    &:before {
      @include ion-icon('refresh', 26px, #333);
    }

    &:hover {
      cursor: pointer;
    }
  }

  &.inspect {
    width: auto !important;
    margin: 42px 0.25% 0;
  }

  @extend %data-table-text-overflow;
}

.alerts-filters {
  background: #97989a;
  color: #fff;
  font-weight: 100;
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  font-size: 13px;
  display: inline-block;
  width: 100%;

  .filter {
    font-size: 11px;
    margin-left: 10px;
    background: #fff;
    border-radius: 8px;
    color: #888;
    padding: 2px 3px 2px 8px;
    vertical-align: middle;
  }
  .remove-filter {
    background: #97989a;
    border-radius: 8px;
    color: #fff;
    display: inline-block;
    line-height: 1;
    width: 1em;
    font-size: 1.1em;
    text-align: center;
    margin-left: 5px;
    vertical-align: baseline;
    cursor: pointer;
  }
}

// DATA-TABLE
.public_fixedDataTable_main {
  border-width: 0;
  width: 100%;
  border-radius: 0 0 8px 8px;
  @include panel-border-fix;
}
.ScrollbarLayout_mainHorizontal {
  border-radius: 0 0 8px 8px;
  border: none;
}

// Table row
.public_fixedDataTableRow_main,
.public_fixedDataTableRow_highlighted {
  background-color: #fff;
  &:hover {
    background: #ecf3ff;
    cursor: pointer;
  }
  &.active-row:hover {
    background: mix(#ecf3ff, #ffefc5);
  }
  .public_fixedDataTableCell_main {
    background: transparent;
  }
}

// Table cell
.fixedDataTableCellLayout_main {
  border-style: solid;
  border-width: 0 1px 1px 0;
  text-align: center;

  &:last-child {
    border-right: 0;
  }

  .sortable {
    .public_fixedDataTableCell_cellContent {
      cursor: pointer;
      padding: 7px 16px;

      &:after {
        @include ion-icon('chevron-down', 12px, #ccc);
        position: absolute;
        top: 50%;
        margin-top: -5px;
        right: 4px;
      }
    }

    &.sort-active {
      &.sort-desc {
        .public_fixedDataTableCell_cellContent:after {
          @include ion-icon('chevron-down', 12px, #333);
        }
      }

      &.sort-asc {
        .public_fixedDataTableCell_cellContent:after {
          @include ion-icon('chevron-up', 12px, #333);
        }
      }
    }
  }

  .priority {
    .public_fixedDataTableCell_cellContent {
      $size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $color_pink_dark;
      border-radius: $size;
      height: $size;
      width: $size;
      line-height: $size;
      padding: 0;
      margin: 5px;
      text-align: center;
      color: #fff;
      text-indent: -1px;
    }

    // leak alerts
    &.priority-A .public_fixedDataTableCell_cellContent {
      background: #f44336;
    }

    &.priority-B .public_fixedDataTableCell_cellContent {
      background: #42a5f5;
    }

    &.priority-C .public_fixedDataTableCell_cellContent {
      background: #8bc34a;
    }

    &.priority-D .public_fixedDataTableCell_cellContent {
      background: #ffc107;
    }

    // noise alerts

    &.noise-priority-A .public_fixedDataTableCell_cellContent {
      background: #ff0000ff;
    }

    &.noise-priority-B .public_fixedDataTableCell_cellContent {
      background: #ff6b6bff;
    }

    &.noise-priority-C .public_fixedDataTableCell_cellContent {
      background: #ffb0b0ff;
    }

    &.noise-priority-D .public_fixedDataTableCell_cellContent {
      background: #b3b3b3ff;
    }

    &.sortable .public_fixedDataTableCell_cellContent {
      background: none;
      padding: 8px 4px;
      height: auto;
      width: auto;
      margin: 0;
      line-height: normal;
      justify-content: center;

      &:after {
        position: static;
        margin: 0 0 0 2px;
      }
    }
  }

  .warning {
    .public_fixedDataTableCell_cellContent {
      $size: 20px;
      height: $size;
      width: $size;
      line-height: $size;
      padding: 0;
      margin: 5px;
      text-align: center;
      color: rgba(255, 255, 255, 0);
      text-indent: -1px;
    }

    &.info {
      &.fading {
        background: {
          image: url('../../../images/ic_fading_leak.png');
          size: 20px 25px;
          repeat: no-repeat;
          position: center;
        }
      }
      &.growing-med {
        background: {
          image: url('../../../images/ic_med_growing_leak.png');
          size: 20px 25px;
          repeat: no-repeat;
          position: center;
        }
      }
      &.growing {
        background: {
          image: url('../../../images/ic_growing_leak.png');
          size: 20px 25px;
          repeat: no-repeat;
          position: center;
        }
      }
    }

    &.warning {
      background-size: 20px 20px;
      background-repeat: no-repeat;
      background-color: rgba(255, 255, 255, 0);
      background-position: center;

      &.gps {
        background-image: url('../../../images/ic-warning-gps.png');
      }

      &.comm {
        background-image: url('../../../images/ic-warning-communication.png');
      }

      &.non-associate {
        background-image: url('../../../images/ic_warning.png');
      }

      &.noise-alert {
        background-image: url('../../../images/ic-noise-alert.png');
      }

      &.rm {
        // background-image: url('../../../images/ic-warning-radio.png');
        background-image: url('../../../images/bad-rm-icon.png');
        background-size: 17px 14px;
      }

      &.inactive {
        background-image: url('../../../images/ic_warning_couple.png');
      }

      &.not-sampling {
        background-image: url('../../../images/ic_warning_couple_no_correlations.png');
      }

      &.have-to-install {
        background-image: url('../../../images/have_to_install.png');
      }

      &.have-to-uninstall {
        background-image: url('../../../images/have_to_uninstall.png');
      }

      &.burst {
        background-image: url('../../../images/ic-burst.png');
      }
      &.bad-pipe {
        background-image: url('../../../images/ic_worning_triangle_bad_pipe.png');
      }
    }
  }

  .drop {
    padding: 16px;
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-color: rgba(255, 255, 255, 0);
    background-position: center;
    background-image: url('../../../images/drop.png');
  }

  .algo {
    border-radius: 20px;
    height: 20px !important;
    width: 20px !important;
    line-height: 20px;
    padding: 0;
    text-align: center;
    margin: 4px auto;

    &.red {
      background-color: red;
    }

    &.green {
      background-color: green;
    }

    &.yellow {
      background-color: yellow;
    }

    &.grey {
      background-color: grey;
    }

    &.orange {
      background-color: orange;
    }

    &.blue {
      background-color: blue;
    }
  }

  .red-background {
    background-color: orangered;
  }

  .cell {
    width: inherit;
  }

  .sensor-link {
    color: #00f;
    text-decoration-line: underline;
    cursor: help; /*url(../../../images/ic_sensor_click_cursor.png), auto;*/
  }

  .sensor-link-cell {
    position: absolute;
    display: block;
    left: 35%;
    right: 35%;
    top: 20%;
    bottom: 20%;
    cursor: zoom-in;
  }

  .public_fixedDataTableCell_cellContent {
    padding: 7px 8px;
  }

  .input-cell .public_fixedDataTableCell_cellContent {
    padding: 0;
  }

  select {
    border: none;
    appearance: none;
    padding: 7px 8px;
    width: 100%;
    font: $font;
    background: url('../../../images/arw-select.png') no-repeat bottom 2px right
      3px;
    background-size: 10px;
    text-align: center;
    text-align-last: center;

    &[disabled] {
      background-image: none;
    }
  }

  input[type='text'] {
    padding: 7px 8px;
    border: none;
    background: none;
    width: 100%;
  }

  &.comment {
    text-align: left;
  }
}
// Table header
.public_fixedDataTable_header {
  background: #fff;

  .public_fixedDataTableCell_main {
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    background: #fff;
    cursor: default;
  }
}

.active-row {
  background: #ffefc5;
}
.pink-row {
  background: #ffeaea;
}
.gray-row {
  background: #efefef;
}
.yellow-row {
  background: #ffdafa;
}
.yellow-hard-row {
  background: #ba6fff;
}
.deleted {
  color: burlywood;
}

.tableCenter {
  text-align: center;
}

.fixed-table-cell-class {
  background-color: white !important;
}

.table-con {
  overflow: hidden;
  height: 80%;
  margin: auto;
  width: 100%;
}

.admin-report-table {
  border-collapse: collapse;
  background-color: #fffdfd;
  margin: 20px 0;
  font-size: 1em;
  text-align: left;
  margin: auto;
  height: auto;
  border-collapse: collapse;
  overflow: auto;
  max-height: 650px;
  width: 100%;
  table-layout: fixed;

  th {
    position: sticky;
    top: -1px;
    z-index: 1;
    background-color: #687da5;
    color: white;
  }

  th,
  td {
    border: 1px solid #ddd;
    padding: 12px 15px;
    word-wrap: break-word;
  }

  tr:nth-of-type(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    // background-color: #f1f1f1;
    background-color: #d9d7d7;
  }
}
